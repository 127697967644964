@import '../mediaQueries';
@import '../Vars';
@import '../flight';

.iso__price__overview__header {
  border-bottom: 1px solid #e4e7ec;
}

.sticky-summary-box {
  margin-bottom: $font-size-base;
  box-shadow: $defaultShadow;
  border: 1px solid #e4e7ec;
  background-color: $white;

  & .custom-summary-flight-date-display > div > span {
    max-width: $font-size-base * 4;
  }
}

.sticky-summary-box-summary {
  padding: $font-size-base * 0.625;
}

.sticky-more-details-button {
  text-align: center;
  padding: $font-size-base * 0.35 $font-size-base * 1.5;
  margin-top: -5px;
  background: $white;
  position: relative;
}

.iso__summaryItem__HOTEL {
  position: relative;

  & .price {
    position: absolute;
    right: 0;
    top: inherit;
    bottom: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    @include medium {
      top: 0;
    }
    white-space: nowrap;
  }

  & .text-muted {
    color: $black !important;
  }
}

.iso__summaryItem__FLIGHT {
  & .price,
  & .iso__summary__number__person {
    display: none;
  }

  & .card-title {
    margin-bottom: $font-size-base * 0.6 !important;
  }

  & .fa-plane {
    transform: rotate(-90deg);
    color: $mediumblue;
  }

  & .text-muted {
    color: $black !important;
  }

  &__wrapper {
    .iso__summaryItem {
      .card h5 {
        font-weight: 400;
        margin-bottom: 0 !important;
      }
    }

    .iso__summaryItem__FLIGHT {
      margin-bottom: 0;

      .card-body > div > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .price {
        display: inline-block;
      }
    }
  }
}

.iso__summaryItem__BUS {
  & .card-title {
    font-weight: unset !important;

    & div {
      align-items: center !important;
    }
  }
}

.sticky-total-price .price {
  color: $white !important;
}

.iso__summary__price__container {
  align-items: start !important;
  justify-content: end !important;
  white-space: nowrap;
  padding-left: 10px;

  &--bold {
    font-weight: bold;
  }
}

.iso__summary__description {
  color: $black !important;
  max-width: 90%;
}

.sticky-summary-box-summary {
  & .card-body {
    padding: 0 !important;
  }
}

.iso__summaryItem__HOTEL,
.iso__summaryItem__EXTRA {
  & .price {
    white-space: nowrap !important;
    position: relative !important;
  }
}

.text-muted .iso__summary__description {
  overflow-wrap: anywhere !important;
  margin-right: 1.875 * $font-size-base !important;
}

.iso__summary__discount-per-person {
  &__description {
    color: $mediumblue !important;
    font-weight: bold !important;
  }

  &__price {
    color: $mediumblue !important;
    font-weight: bold !important;
  }
}

.sticky-total-price {
  padding: $font-size-base * 0.85;
  padding-left: $font-size-base * 1.5;
  margin: $font-size-base * 1.35 0 0 0;
  background-color: $mediumblue !important;
  border-top: 0 solid #e4e7ec;
  font-size: $font-size-base * 1.25;
  flex-wrap: wrap;

  > b:first-child {
    padding-right: $font-size-base;
  }

  @include large {
    margin-top: $font-size-base * 0.35;
  }
  @include medium {
    border-top: 1px solid #e4e7ec;
    background-color: #f5f8f9;
  }
}

.sticky-summary-box-hotline {
  box-shadow: $defaultShadow;
  border: 1px solid #e4e7ec;
  background-color: $white;
  padding: $font-size-base * 0.7;
  padding-left: $font-size-base * 1.625;
  padding-right: $font-size-base;
  margin-bottom: 1.25 * $font-size-base;

  & > h3 {
    color: $blue;
    font-weight: 600;
    font-size: $font-size-base * 1.32;
    margin-bottom: $font-size-base;
  }

  & > div {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: $font-size-base * 0.45;

    & > svg {
      font-size: $font-size-base * 1.75;
      color: $blue;
      margin-right: 10px;
    }

    & > span {
      font-size: $font-size-base * 2;
      color: $blue;
    }
  }

  & > p {
    font-size: $font-size-base * 0.9;
    margin: 0;
  }
}

.price {
  color: $black !important;
}

.summary {
  &__traveler {
    font-weight: 700 !important;
  }

  &__price-per-person {
    font-weight: 700 !important;
    color: $orange;

    & span:first-child {
      display: flex !important;
      justify-content: space-between !important;
    }
  }

  &__discount-per-person {
    font-weight: 700 !important;
    color: $mediumblue;

    & span:first-child {
      display: flex !important;
      justify-content: space-between !important;
    }
  }
}

.summary-fixed-bottom-bar {
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $white;
  padding: $font-size-base * 0.65;
  box-shadow: $defaultShadow;
  z-index: 5;

  & .close-btn:first-child {
    border: 0;
    outline: none;
    text-decoration: underline;
    text-decoration-color: $black;
    color: $black;
  }

  & .price,
  & .iso__price__value {
    font-size: $font-size-base * 1.25;
    font-weight: 700;
    color: $orange;
    margin: 0;
    margin-bottom: -5px;
  }
}

.summary-sticky-bottom-bar {
  background-color: $white;
  padding: $font-size-base * 0.65;
  box-shadow: $defaultShadow;
  z-index: 5;
  margin: -0.625 * $font-size-base;
  margin-top: 0.625 * $font-size-base;
  margin-right: -1.625 * $font-size-base;

  & .close-btn:first-child {
    border: 0;
    outline: none;
    text-decoration: underline;
    text-decoration-color: $black;
    color: $black;
    box-shadow: none;
  }
}

.sticky-summary-overlay {
  .modal-body {
    padding: 0;
  }

  .modal-content {
    border-radius: 0;
    border: 0;
    min-height: 100vh;
    background-color: $white;
    padding: $font-size-base * 0.7;
  }

  .modal-dialog {
    width: 100%;
    max-width: 1200px;
    opacity: 1;
    min-height: 100%;
    margin: auto;
  }

  &-content-wrapper {
    min-height: calc(100vh - 1.875rem);
  }
}

.fixed-scroll__element {
  padding: 0;
  width: 100%;

  &:hover {
    z-index: 2;
  }
}

.sticky-box {
  transition: all 0.3s;
}

.traveler-sticky-summary-wrapper {
  @include large {
    flex: 0 0 28%;
    max-width: 28%;
  }
  padding: 0 !important;
  @include medium {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .summary-fixed-bottom-bar {
    & > div > div {
      flex-wrap: wrap;
      font-size: $font-size-base * 0.85;

      & > .btn:last-child {
        width: 100%;
        margin-top: 0.3125 * $font-size-base;
      }
    }

    @media (min-width: 490px) {
      & > div > div {
        font-size: $font-size-base;

        & > .btn:last-child {
          width: inherit;
          margin-top: inherit;
        }
      }
    }
  }
}

.traveler-sticky-main-content {
  @include large {
    flex: 0 0 72%;
    max-width: 72%;
  }

  @include smallMax {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.iso__summaryItem__HOTEL,
.iso__summaryItem__EXTRA {
  & .card-body {
    & > div > div {
      flex-grow: unset;
      display: flex;
      justify-content: space-between;
    }
  }
}
