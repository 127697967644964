@import '../Vars';

.confirmation {
  &--flight {
    max-width: 350px;
    padding: 0.625 * $font-size-base;

    & > div:first-child {
      font-weight: 600;
      margin-bottom: $font-size-base * 0.6;
    }

    & .custom-summary-flight-date-display > div > div > span:first-child {
      font-weight: normal;
    }

    & .each__traveler__summary__flight {
      text-align: right;
    }

    & .each__traveler__summary__flight__title {
      font-weight: 600;
    }

    & > div > div .each__traveler__summary__flight__title {
      display: none;
    }

    & > div > div:first-child .each__traveler__summary__flight__title {
      display: block;
      margin-bottom: $font-size-base * 0.25;
    }

    & .custom-summary-flight-date-display {
      text-align: right;

      & > div > span {
        max-width: $font-size-base * 5.4375;
      }
    }
  }

  &__booking__container {
    color: black;
    margin: 0 0 1.25 * $font-size-base;
    box-shadow: $defaultShadow;

    .total-price {
      padding: $font-size-base * 0.75 0;
      margin: 0;
      background-color: $white;
      border-bottom: 1px solid $gray-200;
    }

    & h4 {
      font-weight: 700;
      margin-top: 0 !important;
      margin-bottom: $font-size-base !important;
      font-size: $font-size-base * 1.25;
    }

    & .iso__flight-and-accommodation {
      box-shadow: 0 $font-size-base * 0.188 0 #3f4f5a28 !important;
    }
  }

  .iso__confirmation__price__overview__per__traveler {
    padding: $font-size-base * 0.8;
    @include small {
      max-width: 350px;
    }

    & > h4 {
      border-bottom: 1px solid $gray-200;
      margin-bottom: $font-size-base * 0.65 !important;
    }
  }

  &__detail {
    padding: $font-size-base * 0.4;
    background-color: $white;
    @include medium {
      padding: $font-size-base * 0.8;
    }

    & .iso__confirmation__price__overview__per__traveler {
      padding: $font-size-base * 0.8;
      @include small {
        max-width: 350px;
      }

      & > h4 {
        border-bottom: 1px solid $gray-200;
        margin-bottom: $font-size-base * 0.65 !important;
      }
    }

    & .iso__price__overview__header {
      border: 0;
      font-size: $font-size-base;
      @include small {
        max-width: 350px;
      }

      & > div {
        margin-bottom: $font-size-base * 0.25;
      }
    }
  }

  &__booking {
    display: flex;
    box-shadow: $defaultShadow;
    background-color: #e2f0ff;
    font-size: $font-size-base * 1.25;
    padding: $font-size-base;
    font-weight: 700;
    line-height: 1.4;

    @include small {
      padding: $font-size-base * 1.9 $font-size-base * 1.25;
    }

    svg {
      font-size: $font-size-base * 2.5;
      margin-right: 0.625 * $font-size-base;
      margin-bottom: 0.625 * $font-size-base;

      @include small {
        font-size: $font-size-base * 4;
        margin-bottom: 0;
      }
    }

    &-number {
      word-break: break-word;
      font-size: $font-size-base * 1.6;

      @include small {
        word-break: initial;
      }
    }

    &__title {
      font-size: $font-size-base * 2.053;

      &__trendtours {
        font-style: italic;
      }
    }

    &__textblock {
      max-width: $font-size-base * 38.875;
    }
  }

  &__number {
    color: $mediumblue;
    font-size: $font-size-base * 1.734;
  }

  &__emailtext {
    color: $mediumblue;
    font-size: $font-size-base * 1.653;
  }

  &__text {
    font-size: $font-size-base;
    font-weight: 500;
    display: block;
  }

  &__small {
    font-size: $font-size-base * 0.875;
    font-weight: 400;
  }

  &__phone {
    background-color: $white;
    color: #00509b;
    box-shadow: $defaultShadow;
    padding: 0.625 * $font-size-base;

    svg {
      font-size: $font-size-base * 1.5;
    }

    &-number {
      font-size: $font-size-base * 1.6;
      font-weight: 500;
    }
  }

  &__consulting {
    font-weight: 700;
    font-size: $font-size-base * 1.3;
    margin-bottom: 1.25 * $font-size-base;
  }

  &__hours {
    font-size: $font-size-base * 0.8;
    color: initial;

    @include small {
      white-space: nowrap;
    }
  }

  &__back {
    display: flex;
    justify-content: flex-end;
  }

  &__error {
    margin-left: 0.625 * $font-size-base;
    margin-bottom: 1.25 * $font-size-base;

    h2 {
      color: $red;
    }

    div {
      font-size: $font-size-base;
      font-weight: 500;
    }

    @include medium {
      margin-left: 1.5625 * $font-size-base;
    }

    @include large {
      margin-left: 2.5 * $font-size-base;
    }
  }

  &__notice {
    color: #626774;
    padding: 1.5625 * $font-size-base 0.625 * $font-size-base 0.625 * $font-size-base 0.625 *
      $font-size-base;

    h4 {
      font-size: 1.125 * $font-size-base;
      color: #11192b;
    }

    br {
      display: none;
    }

    br + br {
      display: none;
    }
  }

  & .iso__price__overview__header {
    padding: $font-size-base * 0.8;
  }
}

.iso__confirmation__price__overview__per__traveler {
  & > h4 {
    border-bottom: 1px solid $gray-200;
    margin-bottom: $font-size-base * 0.65 !important;
  }

  .total-price {
    padding: $font-size-base * 0.75 0;
    margin: 0;
    background-color: $white;
    border-bottom: 1px solid $gray-200;
    font-size: $font-size-base * 1.25;
  }

  .price {
    color: $orange;
  }

  & h4 {
    font-weight: 700;
    margin-top: 0 !important;
    margin-bottom: $font-size-base !important;
    font-size: $font-size-base * 1.25;
  }

  &__travelers {
    & .each__traveler__summary:last-child {
      margin: 0;
    }
  }

  .each__traveler__summary {
    border-bottom: 1px solid $gray-200;
    padding-bottom: $font-size-base * 0.5;
    margin-bottom: $font-size-base * 0.85;
    font-size: $font-size-base;
    position: relative;

    & > div > div {
      margin-bottom: $font-size-base * 0.7;
    }

    &__flight {
      margin-bottom: $font-size-base * 0.25;
    }

    &__details {
      padding-left: $font-size-base * 0.8;
      color: #626774;
    }

    & > div:first-child {
      font-weight: 600;
      text-transform: capitalize;

      & div:first-child {
        color: #626774;
      }

      & .iso__price__value {
        font-weight: 600;
      }
    }
  }
}
