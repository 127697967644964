@import '../mediaQueries';
@import '../Vars';
@import '~react-multi-carousel/lib/styles';

.carousel-wrapper {
  position: relative;
  overflow: visible;
  transform: scale(0.9);

  @media (min-width: 768px) and (max-width: 991px) {
    transform: scale(1);
  }

  .carousel-slides-container {
    overflow: visible;
    position: unset;

    .react-multi-carousel-item {
      display: flex;
      line-height: inherit;
      margin-bottom: 0;
    }

    .iso__hotel-card {
      overflow: hidden;
      flex: 1;
    }

    .swiper-button {
      position: absolute;
      font-size: 60px;
      line-height: 18px;
      color: #004f9e;
      width: 35px;

      &-prev {
        left: -5.5%;
        @media (max-width: 575px) {
          left: -10%;
        }

        &:after {
          content: none;
        }
      }

      &-next {
        right: -5.5%;
        @media (max-width: 575px) {
          right: -10%;
        }

        &:after {
          content: none;
        }
      }
    }
  }
}
