.flight-time {
  font-size: $font-size-base * 1.25;
}

.flight-result {
  &-row {
    box-shadow: $defaultShadow;

    .airport-name {
      font-size: $font-size-base * 0.96;
      font-weight: 500;
    }

    > div {
      @media (min-width: 1200px) {
        border-right: 1px solid $gray-400;
      }
    }
  }

  &__single-row {
    &:not(:last-child) {
      .flights-box {
        border-bottom: 1px solid $gray-400;
      }

      .airlines-box {
        @include xlarge {
          border-bottom: 1px solid $gray-400;
        }
      }

      .flights-box-divider {
        margin-bottom: 0;
      }
    }

    .flights-box {
      padding-bottom: 0.625 * $font-size-base;

      @include xlarge {
        padding-bottom: 0;
      }
    }
  }
}

.flights-box {
  &--minimal {
    background-color: $white;
  }

  .durationStopInfo {
    .flightLogo {
      border-color: #2e2d2c;
    }

    svg {
      color: #2e2d2c;
    }
  }
}

.airlines-box {
  border-right: 1px solid $gray-400;
}

.best-price__availability__btn.btn-primary {
  border-radius: 0;
}

.best-price__availability__list {
  .flight-result-row {
    background-color: #fff;

    > .col {
      display: flex;
      flex-direction: column;
      @media (max-width: 1199px) {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }

      > div {
        flex: 1 1 50%;
      }
    }

    @media (max-width: 1199px) {
      .best-price__availability__btn {
        position: relative;
        width: calc(100% + 30px);
        left: -10px;
      }
    }
  }
}
