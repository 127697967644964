.package-list-item {
  &__origin {
    color: $orange;
  }

  .iso__package-details {
    &__hr {
      border-top: 2px solid $gray-600;
    }
  }

  .iso__price-option-panel {
    &__button {
      min-width: 116px;
    }
  }
}

.iso__filter__discardContainer {
  justify-content: center;
}

.iso__filter__resetButton {
  color: $secondary !important;
  border-radius: $btn-border-radius;
  padding: 0, 109375 * $font-size-base 0.3125 * $font-size-base;
  background-color: $white;
  border: 2px solid $primary;

  &:hover {
    border-color: $orange;
  }
}

.bestprice-details {
  .magnoliaRichText {
    p {
      margin-bottom: 0.3125 * $font-size-base;
    }
    ul {
      padding-inline-start: 30px;
    }
    hr {
      border-top: 2px solid $gray-400;
    }
  }
  &__infos {
    > div > span:last-child {
      font-size: $font-size-base;
    }
    &__right {
      font-size: $font-size-base;
    }
    &__icon {
      color: $gray-900;
    }
  }
}

.package-info {
  .package-info__data {
    line-height: 1.25;
  }

  &__price {
    > div > div:last-child {
      line-height: 1.3;
    }
  }

  &__data {
    font-size: 0.875 * $font-size-base;
  }

  &__package {
    @include medium {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      > div:first-child {
        order: 2;
      }

      > div:last-child {
        order: 1;
      }
    }

    @include xlarge {
      display: block;
      flex-direction: initial;
      justify-content: initial;

      .package-info__data {
        text-align: right;
      }
    }
  }

  &__details {
    @include small {
      text-align: right;
    }
  }
}
