.extended__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &--success {
    background-color: $white;
  }

  &--button {
    padding-left: 15px !important;
    padding-right: 15px !important;
    font-size: 1 * $font-size-base;
    min-height: 3 * $font-size-base !important;
    max-height: 3 * $font-size-base !important;

    @include large {
      padding-left: 65px !important;
      padding-right: 65px !important;
      font-size: 1 * $font-size-base;
      min-height: 3.75 * $font-size-base !important;
      max-height: 3.75 * $font-size-base !important;
    }
  }

  &__right {
    margin-left: auto;
    padding: 0.75 * $font-size-base;

    & .btn-secondary {
      padding-left: 15px !important;
      padding-right: 15px !important;
      font-size: 1 * $font-size-base;
      min-height: 3 * $font-size-base !important;
      max-height: 3 * $font-size-base !important;
    }

    @include large {
      padding: 1.5 * $font-size-base;
      & .btn-secondary {
        padding-left: 65px !important;
        padding-right: 65px !important;
        font-size: 1 * $font-size-base;
        min-height: 3.75 * $font-size-base !important;
        max-height: 3.75 * $font-size-base !important;
      }
    }
  }

  & .custom-control-label {
    width: 100%;
  }

  &__left {
    flex: 1;
    color: #000;
    white-space: nowrap;
    overflow: hidden;
    padding: 1.25 * $font-size-base;

    &__title {
      padding-left: 1.4 * $font-size-base;
      font-size: 1 * $font-size-base;
      font-weight: bold;
      position: relative;
      text-overflow: ellipsis;
      text-wrap: wrap;

      & > span {
        font-size: 1.25 * $font-size-base;
        line-height: 1.25 * $font-size-base;
        position: absolute;
        left: 0;
      }
    }

    &__contain {
      padding-left: 0;
    }

    .extended__wrapper__left__description {
      white-space: initial;
    }

    &--active {
      & .extended__wrapper__left__title {
        color: $mediumblue;
      }

      & .extended__wrapper__left__description {
        margin-bottom: 1.125 * $font-size-base;
        white-space: initial;
      }

      background-color: $white;
    }

    &__description {
      text-overflow: ellipsis;
      font-size: 0.875 * $font-size-base;
    }

    &__note {
      position: relative;
      font-size: 0.875 * $font-size-base;
      text-overflow: ellipsis;
      padding-left: 1.4 * $font-size-base;

      & > span {
        position: absolute;
        left: 2px;
        top: 0;
        bottom: 0;
        margin: auto;
      }

      & > span > svg {
        color: #00c187;
        font-size: 0.75 * $font-size-base;
      }
    }

    @include medium {
      padding: 1.5 * $font-size-base;
      background-color: $white;

      &__title {
        padding-left: 2.1 * $font-size-base;
        font-size: 1.5 * $font-size-base;

        & > span {
          font-size: 2 * $font-size-base;
          line-height: 2 * $font-size-base;
          position: absolute;
          left: 0;
        }
      }
      &__contain {
        padding-left: 2.1 * $font-size-base;
      }
      &--active {
        & .extended__wrapper__left__description {
          margin-bottom: 1.125 * $font-size-base;
          white-space: initial;
        }
      }
      &__description {
        font-size: 1.25 * $font-size-base;
      }
      &__note {
        padding-left: 2.1 * $font-size-base;
        font-size: 1.25 * $font-size-base;

        & > span > svg {
          color: #00c187;
          font-size: 1 * $font-size-base;
        }
      }
    }
  }

  &__header__separator {
    color: #000;
    margin-top: 1 * $font-size-base;
    margin-bottom: 0.75 * $font-size-base;
    padding: 0 1.125 * $font-size-base;

    & > h2 {
      font-size: 1.5 * $font-size-base;
      font-weight: bold;
    }

    & > p {
      font-size: 1 * $font-size-base;
      font-weight: 400;
      @include medium {
        font-weight: 600;
      }
    }

    @include medium {
      padding: 0;
      margin-top: 2.8 * $font-size-base;
      margin-bottom: 1.5 * $font-size-base;
      & > h2 {
        font-size: 2 * $font-size-base;
      }
      & > p {
        font-size: 1.25 * $font-size-base;
      }
    }
  }

  .taxi-bus-stop {
    border-top: 1px solid $semi-lightgrey;
  }
}

.collapsible__wizard {
  box-shadow: 0 0 8px $gray-400;
  border: 1px solid #e4e7ec;

  &__item {
    &:last-child {
      border-bottom: 0 solid $semi-lightgrey;
    }

    &:first-child {
      border-top: 0 solid $semi-lightgrey;
    }

    border-top: 1px solid $semi-lightgrey;
    border-bottom: 2px solid $semi-lightgrey;
  }
}

.extended__booking {
  &__checkout__nav {
    margin-left: 0.75 * $font-size-base;
    margin-right: 0.75 * $font-size-base;
    @include large {
      margin-left: 1.5 * $font-size-base;
      margin-right: 1.5 * $font-size-base;
    }
  }

  &__room__number {
    font-size: 1.25 * $font-size-base;
    font-weight: 600;
    margin-right: 2 * $font-size-base;
    white-space: initial;
    @include medium {
      font-size: 1.5 * $font-size-base;
    }
  }

  &__room__title {
    display: flex;
    font-size: 1 * $font-size-base;
    font-weight: bold;
    align-items: flex-end;
    margin-bottom: 0.5 * $font-size-base;
    @include medium {
      font-size: 1.25 * $font-size-base;
    }
  }

  &__room__person {
    display: inline-block;

    & > .extended__booking__room__icon {
      color: $mediumblue;

      & svg {
        font-size: $font-size-base;
        @include medium {
          font-size: 1 * $font-size-base;
        }
      }
    }

    & > .extended__booking__room__number__guest {
      font-size: 0.85 * $font-size-base;
      color: $gray-500;
      font-weight: 400;
      @include medium {
        font-size: $font-size-base;
      }
    }
  }

  &__room__half {
    font-size: 0.85 * $font-size-base;
    color: $gray-500;
    font-weight: 400;
    display: block;
    @include medium {
      font-size: $font-size-base;
    }
  }

  &__loader {
    display: block;
    margin-top: 1.2em;

    & > div {
      height: 2em;
      width: 85%;
      display: block;
      margin-bottom: 0.75em;
      background-color: $gray-200;
    }

    & > div:first-child {
      width: 45%;
      height: 2.625em;
    }
  }

  &__whitespace {
    white-space: initial;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    font-size: 1 * $font-size-base;
    font-weight: 600;
    align-items: center;
    width: 100%;

    &--selected {
      font-weight: 700;
    }

    @include medium {
      font-size: 1.25 * $font-size-base;
    }
  }

  &__room__plus_minus {
    display: flex;
    flex-direction: row;
    align-items: center;

    & .btn:disabled {
      opacity: 0.35;

      &:hover {
        opacity: 0.35;
      }
    }

    &__plus,
    &__minus {
      height: 2.5 * $font-size-base;
      width: 2.5 * $font-size-base;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 2.5 * $font-size-base;
      border: 2px solid $mediumblue;
      color: $mediumblue;
      opacity: 0.9;
      cursor: pointer;

      &--disabled {
        opacity: 0.3;
      }

      &:hover {
        opacity: 1;
        color: $mediumblue;
      }

      & svg {
        font-size: 1.2 * $font-size-base;
      }
    }

    &__value {
      font-size: 1.5 * $font-size-base;
      padding-left: 1.125 * $font-size-base;
      padding-right: 1.125 * $font-size-base;
    }
  }

  &__room {
    padding-top: 1 * $font-size-base;
    padding-bottom: 1.25 * $font-size-base;

    & label {
      color: $darkerBlack;
    }
  }

  &__room__wrapper {
    & > div {
      border-bottom: 1px solid $semi-lightgrey;

      &:last-child {
        border: 0;
        padding-bottom: 0;
      }
    }
  }

  &__item__price {
    font-weight: bold;
    color: $darkerBlack;

    & .iso__price__prefix {
      font-size: 1 * $font-size-base;
      margin-right: -3px;
    }

    & .iso__price__value {
      font-size: 1 * $font-size-base;
    }

    & .iso__price__suffix {
      font-size: 0.625 * $font-size-base;
      margin-left: 2px;
      font-weight: 400;
    }

    @include medium {
      & .iso__price__prefix {
        font-size: 1.25 * $font-size-base;
      }
      & .iso__price__value {
        font-size: 1.25 * $font-size-base;
      }
      & .iso__price__suffix {
        font-size: 0.75 * $font-size-base;
      }
    }
  }

  &__room__assign {
    position: relative;
    padding-left: 2 * $font-size-base;
    margin-bottom: 1.25 * $font-size-base;
    margin-top: 0.75 * $font-size-base;

    & .left-divider {
      position: absolute;
      left: 8px;
      top: -0.75 * $font-size-base;
      bottom: 0;
      z-index: 1;
      width: 2px;
      background-color: $gray-300;
    }

    & .select__control {
      border-color: $gray-300;
      border-width: 0 0 1px 0;
      border-radius: 0;
    }

    & .select__placeholder {
      color: $mediumblue;
    }

    & .select__single-value {
      color: $darkerBlack;
      font-weight: 600;
    }

    & .select__menu {
      z-index: 2;
    }
  }
}

.extended__booking__custom__input {
  padding-left: 0;

  & .custom-control-label::before {
    display: none !important;
  }

  display: flex;
  align-items: center;
  position: relative;

  input[type='checkbox'] {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }

  & label {
    padding-left: 1.75 * $font-size-base !important;
  }

  .custom-checkbox {
    position: absolute;
    width: 1.25 * $font-size-base;
    height: 1.25 * $font-size-base;
    border: 2px solid $mediumblue;
    border-radius: 50%;
    cursor: pointer;
    left: 0;
    top: 0.375 * $font-size-base;
    @include medium {
      top: 0.5 * $font-size-base;
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      background: transparent;
      border-radius: 50%;
      transition: background-color 200ms, transform 200ms;
    }
  }

  .extended__booking__item--selected .custom-checkbox::before {
    background-color: $mediumblue;
    transform: translate(-50%, -50%) scale(0.75);
  }

  &--disabled {
    .custom-checkbox {
      border: 2px solid $gray-600;
    }

    .extended__booking__item--selected .custom-checkbox::before {
      background-color: $gray-600;
    }
  }

  .extended__booking__whitespace__description {
    margin-right: 0.5 * $font-size-base;
  }
}

.extended__booking__duration__per-person,
.extended__booking__roomSelection__per-person,
.extended__booking__catering__per-person {
  display: block;
  font-size: 12px;
  margin-top: -8px;
}

.extended__booking__roomSelection__inclusive,
.extended__booking__catering__inclusive {
  font-size: 1 * $font-size-base;
  font-weight: 700;

  @include medium {
    font-size: 1.25 * $font-size-base;
  }
}

.extended__booking__roomSelection__unavailable {
  font-size: 1 * $font-size-base;
  font-weight: 600;
  color: $gray-600;

  @include medium {
    font-size: 1.25 * $font-size-base;
  }
}
