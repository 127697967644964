$FlightIBEHotelBoxShadow: 0px 4px 10px rgba(63, 79, 90, 0.1568627451);
$FlightIBEPriceColor: #11192b;
$FlightIBETextColor: #000000;

.accommodation-adapt-overlay__ibe_flights {
  .iso__accommodation-adapt {
    &-header {
      padding-left: 0.625 * $font-size-base;
    }

    &-header:before {
      display: none;
    }

    &-steps {
      display: none;
    }

    &-col {
      padding: 0.625 * $font-size-base !important;
    }

    &-rooms-container {
      padding: 0.625 * $font-size-base 1,
        5625 * $font-size-base 0.625 * $font-size-base 0.9375 * $font-size-base;
      background-color: white;
      box-shadow: $FlightIBEHotelBoxShadow;
      border-radius: 2px;
    }

    &-rooms-container-bar:after {
      display: none;
    }

    &-row {
      padding-left: 0;
      padding-right: 0;
    }

    &-rooms-container > div:first-child > span {
      margin-bottom: 0.9375 * $font-size-base;

      & > span {
        font-size: 20px;
      }
    }

    &-header > div > div > h3 {
      font-weight: bold;
      font-size: 22px;
    }

    &-header .row:first-child {
      .col-12 {
        flex: 0 0 100% !important;
        max-width: 100% !important;
      }
    }

    &-input-plus {
      padding-top: 1.5625 * $font-size-base;
      padding-bottom: 0.9375 * $font-size-base;
    }

    &-header > div:first-child {
      margin-bottom: 1.25 * $font-size-base;
    }

    &-rooms-options span {
      color: $FlightIBEPriceColor;
      font-weight: bold;
    }

    &-rooms-options p {
      color: $FlightIBETextColor;
    }

    &-footer {
      justify-content: space-between;

      & > button {
        height: 45px;
        padding-left: 25px;
        padding-right: 25px;
      }

      & > button:first-child {
        margin-right: 0;
      }

      @include medium {
        justify-content: flex-end;
        & > button {
          height: 45px;
          padding-left: 40px;
          padding-right: 40px;
        }
        & > button:first-child {
          margin-right: 1.25 * $font-size-base;
        }
      }
    }

    &-footer-spacing {
      height: 0;
    }

    &-wrapper {
      padding: 0.9375 * $font-size-base;
      @include medium {
        padding: 0.625 * $font-size-base;
      }
    }

    &-rooms-icon > svg {
      color: $mediumblue;
    }
  }

  .custom-control-label {
    padding: 0.15625 * $font-size-base 8px 0.15625 * $font-size-base 14px;
  }

  .iso__summary-header {
    padding-top: 0.9375 * $font-size-base;
    padding-bottom: 0.9375 * $font-size-base;

    &_title {
      font-size: 32px;
      font-weight: bold;
    }
  }

  .iso__input-plus-minus-button {
    border: 2px solid $mediumblue !important;
  }

  .iso__input-plus-minus-default-icon {
    color: $mediumblue;
  }

  .modal-dialog {
    max-width: 1140px;
  }
}

.accommodation-adapt-overlay .iso__summary-header {
  border-bottom: 2px solid #e7f0f4;
}

.iso__summary-header__flight {
  & > h2 {
    color: $FlightIBETextColor;
    font-weight: bold;
    font-size: 1.6875 * $font-size-base;
  }

  & > p {
    color: $FlightIBETextColor;
    font-size: 18px;
    margin-bottom: 0.3125 * $font-size-base;
    font-weight: 500;
  }

  & > div {
    & > svg {
      color: $gray-500;
      margin-right: 0.375 * $font-size-base;
    }

    color: $FlightIBETextColor;
  }
}

.ibe__flight {
  .iso__imageProgressBar {
    & div:first-child {
      &:before {
        border-radius: 30px 0px 0px 30px;
      }
    }

    & div:last-child {
      &:before {
        border-radius: 0px 30px 30px 0px;
      }
    }

    &__stepperItem {
      &:before {
        bottom: 0;
        top: inherit;
        left: 0;
        border-bottom-width: 6px;
      }

      &--completed {
        &:before {
          border-color: $mediumblue;
        }
      }

      &--active {
        &:before {
          border-color: $mediumblue;
          border-radius: 0px 30px 30px 0px;
        }
      }
    }

    &__stepperItem:after {
      display: none;
    }

    &__stepperItem {
      flex-direction: row;
      justify-content: center;
    }

    &__stepperItem__stepImage {
      border: 0px;
      background: transparent;
      padding: 0;
      margin: 0;
      width: 35px;
      height: 50px;

      &--active {
        color: $mediumblue;
      }
    }

    &__stepperItem__stepName {
      color: $FlightIBETextColor;
    }
  }

  .form-module__headline {
    & p {
      color: $FlightIBETextColor;
      font-size: $font-size-base;
      font-weight: 400;
    }
  }

  .custom-summary-flight-date-display > div > div {
    min-width: 2.125 * $font-size-base;
  }

  .paymentOptions {
    margin-bottom: -1.25 * $font-size-base;

    &__container {
      padding: 15px;
    }
  }

  .iso__booking-note > div {
    box-shadow: 0 7px 8px #d7d7d7;
  }

  .traveler-header {
    font-size: 25px;
  }

  .iso__travelerAssignment > .mapping-header {
    font-size: 25px;
    margin-bottom: 1.875 * $font-size-base;
  }
}

.payment__info__box {
  position: relative;
  padding-top: 6px;
  display: inline-block;

  & svg {
    color: $mediumblue;
    font-size: $font-size-base * 1.4;
  }
  & .tooltip-popper {
    min-width: 280px;
  }

  cursor: pointer;
}

.prom-code__icon {
  &--masked {
    visibility: hidden;
  }
}

.iso__flight-and-accommodation {
  box-shadow: none;
  margin-bottom: 0;
  border-bottom: 3px solid $semi-lightgrey;

  &__header {
    & > h2 {
      color: $FlightIBETextColor;
    }

    & > p {
      color: $FlightIBETextColor;
    }

    & > div {
      color: $FlightIBETextColor;
    }
  }
}

button {
  font-size: $font-size-base * 1.25 !important;
}

.geounit-checkbox input.form-control {
  font-size: $font-size-base * 1.25 !important;
}
