@import '../mediaQueries';

.loyaltyCard {
  &__input {
    display: flex;
    margin-top: 0.9375 * $font-size-base;

    &__inner {
      position: relative;
      margin-right: 10px;
      width: 100%;

      svg {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 21px;
        cursor: pointer;
      }
    }

    input {
      @extend %input-style;
      padding-left: 10px !important;
      padding-right: 34px;
      border: 1px solid $darkerBlack !important;
      border-radius: 4px !important;
    }

    button.btn {
      height: 40px;

      @include small {
        height: 60px;
      }
    }
  }

  &__not_editing {
    display: flex;
    align-items: center;

    & button {
      width: 60px;
      height: 60px;
      padding: 0 !important;
    }
  }
}
