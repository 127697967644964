$headings-font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI',
  'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';
$font-family-sans-serif: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI',
  'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';
$font-family-base: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI',
  'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';
$font-family-monospace: 'Source Sans Pro', SFMono-Regular, Menlo, Monaco, Consolas,
  'Liberation Mono', 'Courier New', monospace;
$font-family-awesome: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI',
  'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji', 'Font Awesome 5 Free';

$primary: var(--primary);
$blue: var(--blue);
$secondary: $blue;
$button-primary-color: var(--primaryButtonColor);
$darkblue: var(--darkblue);
$lightblue: #99dff1;
$mediumblue: var(--mediumblue);
$focusblue: #2684ff;
$backgroundblue: #e7f0f4;
$footerblue: #c6dbe5;
$tooltipblue: #e2efff;
$green: #3fa535;
$red: #e30513;
$yellow: $primary;
$success: $green;
$orange: #ec6608;
$lightblue: #e7f0f4;
$darkyellow: #fdd63b;
$backgroundyellow: #fff3d9;
$lightgreen: #cfde8c;
$lightred: #f19452;
$yellowGroup: #fff4b2;
$brightYellow: #fffbe5;
$yellowdarker: #ffdc00;

$gray-100: #f7f7f7;
$gray-200: #ededed;
$gray-300: #e4ecef;
$gray-400: #d7d7d7;
$gray-500: #9d9d9c;
$gray-600: #898989;
$gray-700: #5f5f5f;
$gray-800: #28282f;
$gray-900: #28282f;
$grey: #444648;
$lightgrey: #a1a2a3;
$black: #28282f;
$darkerBlack: #000000;
$white: #fff;
$beige: #fff3d9;
$bluegrey: #c1d6e7;
$semi-lightgrey: #d0dae4;

$font-weight-normal: 400;
$font-size-base: 1rem;
$headings-font-weight: 400;
$body-color: $black;
$input-color: $gray-900;
$input-placeholder-color: $gray-500;
$input-background: #a1a2a31a;

$grid-gutter-width: 0.625 * $font-size-base;

$spacers: () !default;
$spacer: 0.625 * $font-size-base;

$spacers: map-merge(
  (
    6: (
      $spacer * 3
    ),
    7: (
      $spacer * 5
    ),
    8: (
      $spacer * 10
    )
  ),
  $spacers
);

$btn-border-radius: 5px;
$btn-font-weight: 700;
$link-color: $blue;
$input-btn-focus-box-shadow: 0 0 0 0.125 * $font-size-base rgba(10, 85, 154, 25%);
$custom-control-indicator-checked-color: #fff;
$custom-control-indicator-active-bg: $blue;
$custom-control-indicator-checked-border-color: $blue;
$custom-control-indicator-checked-bg: $blue;

$defaultShadow: 0px 0px 8px $gray-400;
