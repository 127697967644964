@import './variables';

@mixin break($media, $type: min) {
  @media only screen and (#{$type}-width: $media) {
    @content;
  }
}

@mixin xxlarge {
  @include break(map-get($custom-grid-breakpoints, 'xxl')) {
    @content;
  }
}

@mixin xlarge {
  @include break(map-get($custom-grid-breakpoints, 'xl')) {
    @content;
  }
}

@mixin large {
  @include break(map-get($custom-grid-breakpoints, 'lg')) {
    @content;
  }
}

@mixin mediumMax {
  @include break(map-get($custom-grid-breakpoints, 'md-max'), max) {
    @content;
  }
}

@mixin medium {
  @include break(map-get($custom-grid-breakpoints, 'md')) {
    @content;
  }
}

@mixin smallMax {
  @include break(map-get($custom-grid-breakpoints, 'sm-max'), max) {
    @content;
  }
}

@mixin small {
  @include break(map-get($custom-grid-breakpoints, 'sm')) {
    @content;
  }
}

@mixin xsMax {
  @include break(map-get($custom-grid-breakpoints, 'xs-max'), max) {
    @content;
  }
}

@mixin smallListWithoutLinebreak {
  @include break(map-get($custom-grid-breakpoints, 'sm-listWithoutLineBreak')) {
    @content;
  }
}

@mixin smallListWithoutLinebreakMax {
  @include break(map-get($custom-grid-breakpoints, 'sm-listWithoutLineBreak'), max) {
    @content;
  }
}

@mixin largeListWithoutLinebreak {
  @include break(map-get($custom-grid-breakpoints, 'lg-listWithoutLineBreak')) {
    @content;
  }
}
@mixin largeListWithoutLinebreakMax {
  @include break(map-get($custom-grid-breakpoints, 'lg-listWithoutLineBreak'), max) {
    @content;
  }
}
