@import '../mediaQueries';

.prom-code {
  &__container {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;

    @include large {
      flex-direction: row;
    }

    &__outer {
      margin-bottom: 28px;
    }

    &--small {
      margin-bottom: 0;
      flex-direction: row;

      h3 {
        font-size: $font-size-base;
        font-weight: 700;
        color: $darkerBlack;
      }

      .prom-code {
        &__alert {
          color: $red;
          background-color: #f3cbce;
          border-radius: 4px;
          border-width: 0;
          padding: 0.75rem 4rem 0.75rem 1.25rem;

          & .close {
            color: $red;
            text-shadow: 0 1px 0 $red;
            opacity: 1;
          }
        }

        &__icon {
          margin-right: 0.3125 * $font-size-base;
          width: 24px;
          height: 24px;
          display: flex;
          justify-content: center;
          margin-top: -3px;
        }

        &__left {
          margin-bottom: 0;

          @include small {
            padding-right: 0;
            flex: 0 1 100%;
            max-width: 100%;
          }

          @include medium {
            flex: 0 1 100%;
            max-width: 100%;
          }
        }

        &__input {
          & input {
            border: 1px solid $darkerBlack !important;
          }

          @include medium {
            flex-direction: column;
          }

          @include large {
            flex-direction: row;
          }

          &__inner {
            @include medium {
              margin-bottom: 12px;
            }

            @include large {
              margin-bottom: 0;
            }
          }

          input {
            padding-left: 10px;
          }

          button.btn {
            padding: 0 5px;

            @include small {
              padding: 0 25px;
            }

            @include large {
              padding: 0 20px;
            }
          }
        }
      }
    }
  }

  &__wrapper {
    padding: 0 0.85 * $font-size-base;
  }

  &__left {
    margin-bottom: 25px;

    @include mediumMax {
      flex: 0 1 100%;
      max-width: 100%;
    }

    @include large {
      padding-right: 0.625 * $font-size-base;
      margin-bottom: 0;
      flex: 0 0 65%;
      max-width: 65%;
    }

    .alert {
      margin-bottom: 0;
    }
  }

  &__right {
    display: flex;

    @include mediumMax {
      flex: 0 1 100%;
      max-width: 100%;
    }

    @include large {
      flex: 0 0 35%;
      max-width: 35%;
    }
  }

  &__info {
    background-color: $secondary;
    color: $white;
    padding: 1.25 * $font-size-base;

    > p {
      margin: 8px 0 0;
    }

    svg {
      font-size: 30px;
      margin-right: 15px;

      + span {
        font-size: 22px;
        font-weight: 600;
      }
    }
  }

  &__input {
    display: flex;
    margin-top: 0.9375 * $font-size-base;

    &__inner {
      position: relative;
      margin-right: 10px;
      width: 100%;

      svg {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 21px;
        cursor: pointer;
      }
    }

    input {
      @extend %input-style;
      padding-left: 10px;
      padding-right: 34px;
      border: 1px solid $darkerBlack !important;
    }

    button.btn {
      height: 40px;

      @include small {
        height: 60px;
      }
    }
  }

  &__not_editing {
    display: flex;
    align-items: center;

    & button {
      width: 60px;
      height: 60px;
      padding: 0 !important;
    }
  }
}

.whitelabel-wrapper .prom-code__left {
  margin-bottom: 0;
}
