@import '../mediaQueries';
@import '../Vars';

.simple-list {
  thead tr {
    border-bottom: 1px solid #e4ecef;
  }

  thead tr th {
    height: 60px;
    vertical-align: middle;
    border-top: 0;
    font-size: 18px;
    white-space: nowrap;
    border-bottom: 0;

    &:first-child {
      max-width: 135px;
    }

    &:not(:last-child) {
      border-right: 2px solid $gray-200;
    }

    &:nth-last-child(3) {
      min-width: 135px;
    }

    &:nth-last-child(2) {
      min-width: 115px;
    }

    &:last-child {
      min-width: 145px;
      white-space: normal;
      padding: 0 24px 0 0.75rem;
    }

    &.simple-list__head-item--sortablesmall-group {
      min-width: 50px;
      @include largeListWithoutLinebreak {
        display: block;
      }
    }
  }

  button.btn.btn-primary {
    font-size: 20px;
    height: 50px;
    min-height: 50px;
    padding: 0 5px 1px;
  }

  &__head-item {
    transition: background-color 0.2s linear;
    word-break: break-word;

    &--sortable:not(.simple-list__head-item--sorting-active):hover {
      background-color: $gray-200;
    }

    &--sorting-active {
      background-color: $white;
      color: $secondary;
    }

    &.small-group {
      cursor: default;
      pointer-events: none;

      &:hover {
        background-color: inherit;
      }
    }
  }

  &__sort-icon {
    border-color: transparent transparent $secondary transparent;
    height: 8px;
    border-width: 0 6px 8px 6px;
  }

  td {
    padding: 0.46875 * $font-size-base 0.625 * $font-size-base;
    font-weight: 600;
  }

  tbody tr {
    td:first-of-type {
      .simple-list__body__item {
        white-space: nowrap;
      }
    }
  }

  tbody tr td {
    vertical-align: middle;

    &:last-child {
      @include smallListWithoutLinebreak {
        width: 136px;
        text-align: right;
      }
    }

    &.simple-list__cell {
      &__small-group {
        text-align: center;

        &.no-smallgroup {
          img {
            opacity: 0;
          }
        }
      }

      &__availability {
        text-align: center;

        > div {
          display: inline-block;
          padding: 0 10px;
          border-radius: 4px;

          @include largeListWithoutLinebreak {
            padding: 10px 20px;
          }
        }

        > .simple-list__body__item:first-of-type {
          @include largeListWithoutLinebreakMax {
            display: block;
          }
        }
      }
    }

    .simple-list__body__item {
      hyphens: auto;

      &.simple-list__cell {
        &__price {
          text-wrap: nowrap;
          .simple-list__cell__price__reduced {
            color: $red;
            display: inline;
            font-size: $font-size-base * 1.1;
            text-wrap: nowrap;
          }
          .simple-list__cell__price__striked {
            padding-left: 10px;
            color: $mediumblue;
            font-size: $font-size-base * 0.9;
            display: inline;
            text-wrap: nowrap;
          }
        }

        &__availability {
          display: inline-block;
          padding: 0 10px;
          border-radius: 4px;
          text-align: center;
          min-width: 50px;

          @include largeListWithoutLinebreak {
            padding: 1px 10px 10px;
            height: 30px;
            min-width: 50px;
          }

          &--level1 {
            background-color: $lightred;
          }

          &--level2 {
            background-color: $lightgreen;
          }
        }
      }
    }

    img {
      width: 30px;
    }
  }

  &__sort {
    &-dropdown > button {
      &:active {
        border-color: $primary !important;
        background-color: $white !important;
        color: $secondary !important;
      }
    }

    &-dropdown {
      @include largeListWithoutLinebreakMax {
        display: block;
      }
    }
  }

  &__head {
    // custom breakpoints to prevent linebreaks:
    @include largeListWithoutLinebreakMax {
      display: none;
    }
    @include largeListWithoutLinebreak {
      display: contents;
    }
  }

  &__body {
    &--smallest {
      .simple-list__body__container {
        display: grid;
        grid-gap: 5px;
        align-items: end;
        grid-template-columns: 30px auto auto;

        .simple-list__cell__startDate {
          grid-row: 1;
          grid-column: 2;
          font-size: $font-size-base;

          &--img {
            grid-row: 1;
            grid-column: 1;
            img {
              width: 14px;
            }
          }
        }
        .simple-list__cell__small-group {
          grid-row: 1;
          grid-column: 3;
          justify-self: end;

          &.no-smallgroup {
            img {
              opacity: 0;
            }
          }
        }
        .simple-list__cell__origin {
          grid-row: 2;
          grid-column: 2;
          font-size: $font-size-base;

          &--img {
            grid-row: 2;
            grid-column: 1;
            img {
              width: 14px;
            }
          }
        }
        .simple-list__cell__availability {
          text-align: left;
          grid-row: 3;
          grid-column: 2;
          justify-self: left;
          font-size: $font-size-base;

          &--img {
            grid-row: 3;
            grid-column: 1;
            img {
              width: 14px;
              margin-bottom: 3px;
            }
          }
        }
        .simple-list__cell__price {
          grid-row: 2 / span 2;
          grid-column: 3;
        }
        div:last-of-type {
          grid-row: 4;
          grid-column: span 3;
        }
      }
      .simple-list__body__item {
        text-align: left;
        &.simple-list__cell__price {
          display: flex;
          flex-direction: column;
          .simple-list__cell__price__reduced {
            order: 2;
            align-self: flex-end;
          }
          .simple-list__cell__price__normal {
            order: 2;
            align-self: flex-end;
            font-size: $font-size-base * 1.1;
          }
          .simple-list__cell__price__striked {
            order: 1;
            align-self: flex-end;
          }
        }
      }

      button {
        margin-top: 15px;
      }

      // custom breakpoints to prevent linebreaks:
      @include smallListWithoutLinebreakMax {
        display: contents;
      }
      @include smallListWithoutLinebreak {
        display: none;
      }
    }
    &--mobile {
      @include smallListWithoutLinebreakMax {
        display: none;
      }

      @include smallListWithoutLinebreak {
        display: contents;
      }

      @include largeListWithoutLinebreak {
        display: none;
      }

      .simple-list__cell__startDate {
        font-size: $font-size-base;
        div img {
          display: none;
        }
      }
      .simple-list__cell__origin {
        font-size: $font-size-base;
        div img {
          display: none;
        }
      }
      .simple-list__cell__availability {
        font-size: $font-size-base;
        div:first-of-type {
          display: none;
        }
      }
      .simple-list__cell__availability--img {
        div img {
          display: none;
        }
      }
      tr:not(.simple-list__body__special) {
        .simple-list__cell__origin--img {
          div img {
            display: none;
          }
        }
      }
      .simple-list__body__special {
        .simple-list__cell__origin--img {
          div:last-of-type img {
            display: none;
          }
        }
      }
    }
    &--desktop {
      @include largeListWithoutLinebreakMax {
        display: none;
      }

      @include largeListWithoutLinebreak {
        display: contents;
      }
    }

    &__item {
      min-width: 82px;
      font-size: $font-size-base * 0.9;

      @include largeListWithoutLinebreak {
        font-size: $font-size-base * 1.1;
      }
    }
  }

  .table-striped tbody tr {
    &:nth-of-type(odd) {
      background-color: $gray-200;

      &.simple-list__body__special {
        background-color: $yellowGroup;
      }
    }

    &:nth-of-type(even) {
      background-color: darken($gray-100, 0.5%);

      &.simple-list__body__special {
        background-color: $brightYellow;
      }
    }
  }
}
