@import '../Vars';
@import '../mediaQueries';

.optional-offer {
  box-shadow: $defaultShadow;

  &__icon-container {
    svg {
      color: $gray-400;
    }
  }

  &__radio {
    input,
    label {
      cursor: pointer;
    }
  }

  &__headline {
    font-weight: 600;
    font-size: 26px;
    line-height: 1;
  }

  &__sub-headline {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 1.25 * $font-size-base;
  }

  &__extra {
    box-shadow: $defaultShadow;

    &__container {
      @include small {
        flex: 0 0 50%;
        max-width: 50%;
      }

      @include medium {
        flex: 0 0 100%;
        max-width: 100%;
      }

      @include large {
        flex: 0 0 50%;
        max-width: 50%;
      }

      @include xlarge {
        flex: 0 0 33.3333333%;
        max-width: 33.3333333%;
      }

      &--horizontal {
        .optional-offer__extra__content {
          @include xlarge {
            justify-content: space-between;
            width: 100%;
          }
        }

        .optional-offer__extra__content__button > div:first-child {
          @include medium {
            white-space: nowrap;
          }

          @include xlarge {
            white-space: nowrap;
          }
        }
      }
    }

    &__content__button {
      flex-wrap: nowrap;

      > div:first-child {
        white-space: nowrap;
        margin-right: 10px;
        font-size: 16px;

        @include medium {
          white-space: initial;
        }

        @include large {
          white-space: nowrap;
        }

        @include xlarge {
          white-space: initial;
        }
      }

      .btn.btn-primary {
        &:active,
        &:focus {
          color: $blue;
        }
      }

      .btn.btn-secondary {
        background-color: $mediumblue;
        border: none;
        color: $white;

        &:hover {
          color: $white;
        }
      }
    }
  }

  &__hotel-upgrade-label__stars {
    color: $orange;
  }

  &__modal {
    color: $darkerBlack;

    &__image {
      img {
        aspect-ratio: 770/476;
        max-height: initial;
        height: auto;
      }
    }

    &__message {
      padding-left: calc(0.9375rem + 15px);
      padding-top: 0.15625 * $font-size-base;
      @include medium {
        padding-left: 0;
      }
    }

    &__content {
      padding-top: 1.75 * $font-size-base;

      & h3 {
        font-size: 1.5 * $font-size-base;
        line-height: 1.5 * $font-size-base;

        & p {
          font-size: 1.5 * $font-size-base;
          line-height: 1.5 * $font-size-base;
        }
      }
    }

    &__divider {
      background-color: #c3c9cf;
    }

    &__selection {
      padding-left: 0.938 * $font-size-base;
      padding-right: 0.938 * $font-size-base;
      padding-bottom: 0;
      @include small {
        padding-left: 1.75 * $font-size-base;
        padding-right: 1.875 * $font-size-base;
      }

      & > div:first-child {
        font-size: 1.25 * $font-size-base;
      }

      & label {
        font-size: 1.25 * $font-size-base !important;
        font-weight: 700 !important;
        color: $darkerBlack;
      }

      &__divider {
        height: 1px;
        background-color: #d0dae4;
        margin-top: 1.25 * $font-size-base;
      }
    }

    &__total-price {
      width: 100%;
      justify-content: space-between;
      margin-bottom: 1.25 * $font-size-base;

      &__label {
        font-size: 1.25 * $font-size-base;
      }
    }

    &__buttons__container {
      @include small {
        flex-direction: column;
      }

      padding-top: 1.75 * $font-size-base;
      padding-bottom: 1.75 * $font-size-base;
      margin-top: 0px;

      & .btn {
        border-radius: 4px !important;
        border-width: 2px;
        height: 3.75 * $font-size-base !important;
        font-size: 1.25 * $font-size-base !important;
      }

      & .iso__price__value {
        font-size: 1.875 * $font-size-base !important;
        margin-right: 0;
      }
    }

    &__checkbox {
      align-items: flex-start;

      & .iso__price__value {
        font-size: 1.25 * $font-size-base;
      }
    }

    &__buttons {
      margin-right: 0;

      @include small {
        align-self: flex-end;
      }
    }

    & .custom-checkbox .custom-control-label::before,
    & .custom-checkbox .custom-control-label::after {
      top: 0.5 * $font-size-base;
    }
  }
}
