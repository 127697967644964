@import 'templates';
@import '../mediaQueries';
@import '../Vars';

%input-style-extended {
  @extend %input-style;
  background-color: $primary;
  padding-left: 15px;
}

.product-ibe {
  color: $black;

  h2,
  h3 {
    font-weight: 700;
  }
}

.packages-list {
  &__loading {
    min-height: 160px;

    .bs-tooltip-auto.tooltip-popper {
      padding: 0;
    }

    .simple-list__cell__bus-stops-icon {
      color: var(--blue);
      margin-left: 8px;
      cursor: pointer;
      @include largeListWithoutLinebreak {
        display: none;
      }
    }

    .tooltip-popper {
      max-width: 340px;
      opacity: 1 !important;
      box-shadow: 1px 4px 10px #3f4f5a28;
      border-radius: 2px;
      padding: 0;
      z-index: 40;
      .arrow {
        display: none;
      }
    }

    .tooltip-inner {
      background-color: $tooltipblue;
      color: black;
      max-width: 350px;
      padding: 16px;

      .tooltip__text {
        font-size: $font-size-base;
        font-weight: bold;
        margin-bottom: 1px;
        text-align: left;
      }

      .tooltip__info {
        font-size: $font-size-base;
        text-align: left;

        li {
          position: relative;
          padding-left: 5px;
        }

        .bus_tooltip_icon {
          color: $red;
          padding-right: 5px;
          font-weight: 900;
        }

        li::marker {
          content: none;
        }
      }
    }

    .alert-bus-package-criteria-info {
      background-color: $gray-300;
    }
  }
}

.iso__number-selector {
  .label {
    color: $blue;
    font-size: 20px;
    font-weight: 700;

    p {
      margin-bottom: 0;
    }
  }

  button {
    padding-left: 20px !important;
    padding-right: 20px !important;
    font-size: 30px !important;
    min-width: 65px;
  }

  button:active,
  button:focus,
  button:disabled {
    color: $blue !important;
  }
}

.bus-filter-container {
  .dropdown-filter {
    &__pills {
      display: none;
    }
    &__pax {
      > label > svg {
        pointer-events: none;
      }
    }
    &__container {
      .dropdown-filter__search {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 30px;
        position: relative;
        height: 40px;
        flex: 1 0 100%;
        padding-left: 0;

        @media (min-width: 1176px) {
          height: 58px;
          flex: 1 0 20%;
          margin-bottom: 15px;
        }

        button {
          width: 100%;
        }
      }

      .dropdown-filter__filter {
        background-color: $white;
        margin-bottom: 5px;

        .form-group {
          margin-bottom: 0;
        }

        .select__control {
          background-color: $input-background;
          font-weight: normal !important;
          font-size: $font-size-base !important;
          border: 1px solid $lightgrey !important;

          & input {
            color: $darkerBlack !important;
          }

          .select__indicators {
            display: none;
          }

          &:hover {
            outline: none !important;
          }
        }

        .date-range-single {
          border: 1px solid $lightgrey;
          border-radius: 5px;
          &:hover {
            box-shadow: none;
          }

          .iso__date-range-input.btn {
            height: 40px;
            @include small {
              height: 58px;
            }
            border: none;
            outline: none;
            appearance: none;
            border-radius: 5px;
            padding-left: 15px;
            background-color: $input-background;

            .iso__date-range-input_text-container {
              font-size: $font-size-base;
              justify-content: left;
            }

            &:focus {
              outline: none;
              box-shadow: 0 0 0 1px $focusblue;
            }
          }
          .iso__date-range-input_divider {
            height: 1px;
            width: 5px;
            position: relative;
            top: 12px;
            background-color: $black;
            span svg {
              display: none;
            }
          }
        }

        .custom-fieldset-label {
          position: relative;
          font-size: $font-size-base;
          width: 100%;
        }

        .custom-fieldset-label input[type='text'] {
          border: 1px solid $lightgrey;
          background-color: $input-background;
          appearance: none;
          -moz-appearance: none;
          -webkit-appearance: none;
          border-radius: 5px;
          padding-left: 15px;
          height: 40px;
          width: 100%;

          @include small {
            height: 60px;
          }
          outline: none;
          font-size: $font-size-base;

          &:hover {
            outline: none;
          }

          &:focus {
            border-color: $lightblue;
          }
          &::placeholder {
            color: $darkerBlack !important;
          }
        }

        .post-code-select {
          [class*='control'] {
            border: none;

            > div:not(:first-child) {
              display: none;
            }

            > div:first-child {
              background-color: $input-background;
              border-radius: 5px;
              border: 1px solid $lightgrey;
              white-space: nowrap;
              height: 40px;
              cursor: pointer;
              @include small {
                height: 60px;
              }

              > div {
                color: $darkerBlack;
              }

              > div:not([class*='placeholder']) {
                height: 30px;
                @include small {
                  height: 50px;
                }

                input {
                  height: 30px;
                  @include small {
                    height: 50px;
                  }
                }
              }
            }
          }

          [class*='menu'] {
            z-index: 2;
          }

          &:hover {
            cursor: pointer;
          }
        }

        .invalid-post-code {
          border: 1px solid $red;
          border-radius: 5px;
        }

        .custom-fieldset-label .placeholder {
          position: absolute;
          left: 15px;
          top: -10px;
          color: $grey;
          font-size: $font-size-base * 0.6;
          background: $white;
          border-radius: 2px;
          padding: 0 5px;
          z-index: 1;
        }

        .dropdown-filter__postalCode {
          .geounit-checkbox {
            &__popover {
              @include small {
                transform: translate3d(0px, 0px, 0px);
              }
            }

            &__container {
              min-width: 150px;
            }
            &__angle__wrapper svg {
              display: none;
            }

            input.form-control {
              text-align: left;
              color: $darkerBlack !important;
              font-size: $font-size-base !important;
              font-weight: normal;
              background-color: $input-background;
              border: 1px solid $lightgrey !important;

              &:hover {
                background-color: $input-background;
                outline: none !important;
              }

              &:focus:active {
                outline: none !important;
                box-shadow: 0 0 0 1px $focusblue;
              }
            }

            &--disabled {
              background-color: $gray-200;
              border-radius: 5px;
            }
          }
        }

        .paxRoomSelection {
          background-color: $input-background;
          border: none;

          &__label {
            color: $darkerBlack;
            font-weight: normal;
            font-size: $font-size-base !important;
          }
        }
      }
    }
  }

  .single-selections-container {
    display: block;
    background-color: transparent;
    margin-bottom: 6px;
    position: relative;
    padding-left: 0 !important;
    z-index: 0;
    margin-left: 0;

    .small-group-selection {
      display: flex;
      font-weight: 700;
      margin-bottom: 5px;
      padding: 5px 0 0;
      background-color: transparent;
      flex-wrap: wrap;
      height: 46px;
      & .custom-control > label {
        padding-top: 5px;
        color: $darkerBlack;
      }
    }
  }

  .bs-tooltip-auto.tooltip-popper {
    padding: 0;
  }

  .tooltip-popper {
    max-width: 340px;
    opacity: 1 !important;
    box-shadow: 1px 4px 10px #3f4f5a28;
    border-radius: 2px;
    padding: 0;
    z-index: 4;
    .arrow {
      display: none;
    }
  }

  .tooltip-inner {
    background-color: $tooltipblue;
    color: black;
    max-width: 350px;
    padding: 16px;

    .tooltip__text {
      font-size: $font-size-base;
      font-weight: bold;
      margin-bottom: 1px;
    }

    .tooltip__info {
      font-size: $font-size-base;
      text-align: left;
    }
  }
}

.small-group-selection {
  display: flex;
  font-weight: 700;
  margin-bottom: 15px;
  padding: 15px 15px 0;
  background-color: $yellowGroup;
  flex-wrap: wrap;

  &__info {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    margin-right: 20px;
    margin-bottom: 15px;

    img {
      max-height: 44px;
      margin-right: 15px;
    }

    &__end {
      display: flex;
      flex: 1 1 auto;
      align-items: center;
      margin-right: 20px;
      margin-bottom: 10px;

      img {
        max-height: 28px;
        margin-left: 10px;
      }

      svg {
        margin-left: 10px;
        min-width: 20px;
        height: 20px;
        color: $blue;
      }
    }
  }

  .custom-checkbox {
    display: flex;
    align-items: center;
    flex: 0 1 auto;
    margin-bottom: 15px;

    > label {
      font-weight: 700;
      color: $secondary;
      padding-right: 0;
    }
  }
}

.dropdown-filter {
  &__row {
    display: flex;
    flex-wrap: wrap;
  }

  &__filter {
    padding: 0 10px;
    margin-bottom: 15px;

    .custom-fieldset-label {
      width: 100%;
    }

    @include largeListWithoutLinebreakMax {
      flex: 0 0 100% !important;
      max-width: 100% !important;
      padding: 0;
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    .date-range-single__button {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .geounit-checkbox {
      & > div {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .date-range-single__button,
    .geounit-checkbox input.form-control,
    .select__control {
      @extend %input-style-extended;
      @extend .btn;
      @extend .btn-primary;
      text-align: center;
      padding-left: 15px !important;

      &.btn.btn-primary {
        padding-right: 25px;
      }

      height: 40px;

      @include small {
        height: 60px;
      }

      &:hover {
        outline: 2px solid $orange !important;
        color: $button-primary-color !important;
      }

      &:active,
      &:focus {
        outline: 0;
        box-shadow: none;
        border-color: $primary;
      }
    }

    .select__control {
      width: 100%;
      display: flex;

      .select__single-value,
      .select__indicator {
        display: none;
      }

      input {
        caret-color: transparent;
        color: $secondary !important;
      }
    }

    .geounit-checkbox {
      .geounit-checkbox__angle__wrapper {
        right: 15px;
        top: 0;
        bottom: 0;
        transform: none;
        display: flex;
        align-items: center;

        svg {
          font-size: 22px;
        }
      }

      &.geounit-checkbox--open .geounit-checkbox__angle {
        transform: rotate(0);
      }

      .Popover {
        top: 68px !important;
      }

      input {
        color: $button-primary-color !important;
      }

      button {
        &.geounit-checkbox__btn-remove {
          @extend .btn-secondary;

          margin-right: 6px;

          &:hover {
            background-color: $white !important;
            color: $secondary !important;
          }

          &::before {
            content: initial;
          }
        }

        &.btn-secondary {
          @extend .btn-primary;
        }
      }

      .form-control:disabled {
        color: $gray-800 !important;

        &:hover {
          color: $secondary !important;
        }
      }

      &--disabled {
        .geounit-checkbox__angle__wrapper {
          z-index: 1;
          opacity: 0.65;

          svg {
            color: $gray-800 !important;
          }
        }

        &:hover {
          .geounit-checkbox__angle__wrapper svg {
            color: $secondary !important;
          }
        }
      }

      &__button-container {
        flex-direction: column;

        > button + button {
          margin-top: 10px;
        }

        @include small {
          flex-direction: row;

          > button {
            flex: 1 0 calc(50% - 3px);

            + button {
              margin-top: initial;
            }
          }
        }
      }
    }

    .paxRoomSelection {
      position: relative;

      .paxRoomSelection__label {
        font-size: $font-size-base * 1.25 !important;
        position: absolute;
        z-index: 1;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
        font-weight: 700;
        color: $secondary;
        pointer-events: none;
      }
    }
  }

  &__pill {
    padding: 0 5px;
    margin-bottom: 10px;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    &--2 {
      max-width: 300px;

      > div {
        display: flex;
        align-items: center;

        > span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
        }
      }
    }

    &__inner {
      border-radius: 4px;
      background-color: $semi-lightgrey;
      padding: 3px 10px;

      svg {
        display: inline-block;
        margin-left: 15px;
        cursor: pointer;
      }
    }
  }

  &__pax {
    svg {
      position: absolute;
      font-size: 22px;
      color: $secondary;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
    }

    &--disabled {
      .select__control {
        opacity: 0.65;

        &:hover {
          outline: 2px solid $orange;
        }
      }

      .paxRoomSelection__label,
      svg {
        color: $gray-800 !important;
        opacity: 0.65;
      }

      &:hover {
        .paxRoomSelection__label,
        svg {
          color: $secondary !important;
        }

        .select__control {
          outline: 2px solid $orange !important;
        }
      }
    }
  }

  &__postalCode {
    .remove-icon {
      svg {
        position: absolute;
        font-size: 20px;
        color: $black;
        left: 60px;
        top: 50%;
        transform: translateY(-50%);
      }
      .separator {
        position: absolute;
        left: 85px;
        background-color: $lightgrey;
        width: 1px;
        height: 38px;
        @include small {
          height: 58px;
        }
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &--disabled {
      .select__control {
        opacity: 0.65;

        &:hover {
          outline: 2px solid $orange;
        }
      }
    }
  }
}

.date-range-single {
  &__button {
    svg {
      position: absolute;
      right: 15px;
      font-size: 22px;
    }
  }

  &__close {
    position: absolute;
    right: 3px;
    top: 3px;
    font-size: 1.5625 * $font-size-base;
    cursor: pointer;
    color: $secondary;
  }

  &__custom-container {
    > label {
      font-size: 0.875 * $font-size-base !important;
      font-weight: 600;
    }

    .select__control {
      padding-left: 18px !important;
    }
  }

  &__input {
    position: relative;

    label {
      font-size: 12px;
      white-space: nowrap;
    }

    &.date-range-single__input--selected input.form-control {
      border: 1px solid $secondary !important;
    }

    svg {
      position: absolute;
      bottom: 12px;
      left: 10px;
      font-size: 17px;
      color: $lightgrey;

      @include small {
        bottom: 22px;
      }
    }

    &:first-child {
      padding-right: 5px;
    }

    &:last-child {
      padding-left: 5px;
    }

    input {
      @extend %input-style;
      padding-left: 35px !important;

      cursor: pointer;

      &:disabled {
        background-color: $white;
      }
    }

    &:last-child input {
      padding-left: 28px !important;
    }

    > label {
      font-size: 0.875 * $font-size-base !important;
      font-weight: 600;
    }
  }

  &__datepicker {
    margin-bottom: 20px;

    .react-datepicker {
      border: 0 !important;

      &__navigation.react-datepicker__navigation {
        &--next {
          top: 12px;
          right: 0;
        }

        &--previous {
          top: 12px;
          left: 0;
        }
      }

      &__current-month {
        margin-bottom: 10px;
      }

      &__month {
        margin: 0 !important;

        &-container {
          padding: 0 !important;
        }
      }

      &__day {
        border: 0;

        &-name {
          width: 36px;
          height: 28px;
        }

        &:hover {
          border-radius: 6px 0 0 6px;
          background-color: lighten($secondary, 40%);
          color: $white !important;
        }

        &--outside-month:hover {
          color: $gray-400 !important;
        }

        &--in-range,
        &--in-selecting-range,
        &--range-start,
        &--range-end,
        &--selecting-range-start,
        &--selecting-range-start:not(.react-datepicker__day--today),
        &--selecting-range-end {
          &:hover {
            background-color: lighten($secondary, 40%) !important;
            color: $white !important;
          }
        }

        &--outside-month:hover {
          background-color: $white !important;
          color: $gray-400 !important;
        }

        &.react-datepicker__day--excluded:hover {
          border-radius: initial;
          background-color: $white;
        }

        &--outside-month {
          visibility: visible;
        }

        &--in-selecting-range,
        &--in-range {
          &.react-datepicker__day--highlighted {
            background-color: lighten($secondary, 40%) !important;
          }
          &.react-datepicker__day--range-end,
          &.react-datepicker__day--range-start {
            background-color: $secondary !important;
          }
          background-color: $white !important;
          color: $black !important;

          &.react-datepicker__day--disabled,
          &.react-datepicker__day--excluded {
            color: $gray-400 !important;

            &:hover {
              background-color: $white !important;
            }
          }
        }

        &--selecting-range-start,
        &--selecting-range-start.react-datepicker__day--selecting-range-end,
        &--range-start {
          background-color: $secondary !important;
          color: $white !important;
          border-radius: 6px 0 0 6px !important;
        }

        &--selecting-range-end,
        &--range-end {
          background-color: $secondary !important;
          color: $white !important;
          border-radius: 0 6px 6px 0 !important;
        }

        &.react-datepicker__day--disabled {
          background-color: $white;
          color: $gray-400;

          &:hover {
            background-color: $white;
            color: $gray-400 !important;
          }
        }
      }
    }
  }

  &__custom-container {
    margin-bottom: 20px;
  }

  .popover {
    font-size: 16px;
    max-width: 290px;
    border-radius: 4px;
    box-shadow: 0 0 8px -3px $gray-500;
  }

  &__popover {
    .arrow {
      display: none;
    }

    &__inner {
      padding: 15px;
      background-color: $white;

      .react-datepicker {
        width: 260px;
      }
    }

    .react-datepicker {
      .react-datepicker__day--keyboard-selected:not(.react-datepicker__day--today):hover {
        background-color: lighten($secondary, 40%);
      }

      &__day {
        &:hover {
          background-color: lighten($secondary, 40%);
        }

        &--outside-month {
          color: $gray-400 !important;
          background-color: $white !important;
          border-radius: 0 !important;
        }
      }
    }
  }

  &__modal {
    max-width: initial;
    margin: 15px;
    height: calc(100vh - 30px);

    .modal-content {
      height: 100%;
    }

    .select__control {
      padding-left: 18px;
      height: 40px;
      border-radius: 4px;
      border: 1px solid $semi-lightgrey !important;
      color: $gray-800;

      .select__indicators {
        display: none;
      }
    }

    .react-datepicker {
      margin: 20px auto 0;
    }

    .date-range-single__input {
      flex: 1 1 50%;
    }
  }

  .react-datepicker__day--highlighted {
    background-color: lighten($secondary, 40%) !important;
  }

  .iso__date-range-input_text-container {
    display: flex;
  }

  .iso__date-range-input_text-container {
    flex-wrap: wrap;
  }

  .iso__date-range-input_divider {
    margin: 0 4px;
  }

  &__same-day {
    &.react-datepicker__day--range-start,
    &.react-datepicker__day--range-end,
    &.react-datepicker__day--selecting-range-end,
    &.react-datepicker__day--selecting-range-start {
      &:not(.react-datepicker__day--today) {
        background-color: transparent !important;
        color: initial !important;
      }
    }
  }
}

.thg-search .search-form .iso__service-search .date-range-single {
  label {
    white-space: nowrap;
  }

  .date-range-single__input.date-range-single__input--selected input.form-control {
    border: 1px solid $secondary !important;
  }
}

.checkout {
  &__extras {
    box-shadow: none;

    &__name-form {
      margin-bottom: 35px;

      &__label {
        color: $secondary;
        font-weight: 700;

        &--first {
          white-space: initial;
          color: $black;

          & .payment__info__box svg {
            font-size: 1.125 * $font-size-base;
            @include medium {
              font-size: 1.25 * $font-size-base;
            }
          }

          @include large {
            padding-top: 30px !important;
          }
        }

        @include large {
          padding-top: 48px;
        }
      }

      .input--error {
        margin-bottom: 5px;

        .form__error {
          position: relative;
          top: 0;
        }
      }
    }

    .form-control {
      height: 40px;

      @include medium {
        height: 60px;
      }
    }
  }
}
