.list-item {
  border: 1px solid $semi-lightgrey;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  width: 100%;
  cursor: pointer;

  &__content {
    background-color: $white;
    padding: 14px;
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__image {
    height: 250px;
    width: 100%;
    object-fit: cover;

    @include small {
      height: 220px;
    }

    &__container {
      position: relative;
      min-height: 100px;
      background-color: $white;
    }

    &__marker {
      width: auto;
      height: 100%;

      &__container {
        display: flex;
        align-items: center;
        justify-content: center;
        max-height: 48px;
        height: 100%;
        width: 48px;
        border-radius: 4px;
        background-color: rgba(255, 255, 255, 0.8);
        padding: 12px;
        position: absolute;
        top: 12px;
        right: 12px;
      }
    }

    &__small-group {
      width: calc(25% + 1.25rem);
      position: absolute;
      bottom: 10px;
      left: 10px;
    }
  }

  &__name {
    font-size: 1.5 * $font-size-base;
    font-weight: 700;
    line-height: 1.1;
    margin-bottom: $font-size-base;
  }

  &__description {
    ul {
      margin-bottom: 0;
    }
  }

  &__info {
    font-size: 0.9375 * $font-size-base;
    display: flex;
    flex-wrap: wrap;
  }

  &__date {
    margin: 0 1.25 * $font-size-base 0 0.3125 * $font-size-base;

    div:first-child {
      font-weight: 700;
      margin-bottom: 0.125 * $font-size-base;
      line-height: 1;
    }
  }

  &__partner {
    margin-top: 0.375 * $font-size-base;

    div:first-of-type {
      font-weight: 700;
      margin-bottom: 0.25 * $font-size-base;
      line-height: 1;
    }

    img.list-item__partner__image {
      max-width: 75px;
      width: 100%;
    }
  }

  &__price {
    color: $red;
    font-weight: 700;
    font-size: 1.8 * $font-size-base;
    line-height: 1;
    white-space: nowrap;

    &__row {
      margin-top: 1.5 * $font-size-base;
    }

    &__prefix {
      font-size: 1.25 * $font-size-base;
      color: $secondary;
      font-weight: 700;
    }

    &__suffix {
      color: $secondary;
      font-size: 1.125 * $font-size-base;
    }

    &__old {
      font-size: $font-size-base;
      color: $secondary;
      font-weight: 700;

      .iso__price__value {
        text-decoration: line-through 2px;
      }
    }

    &--first {
      margin-top: 1.125 * $font-size-base;
      color: $secondary;
    }

    &-row {
      > div {
        &:first-child {
        }
      }
    }
  }

  &__context {
    margin-top: 0.1875 * $font-size-base;
    font-size: 0.75 * $font-size-base;
  }

  &__cta {
    width: 100%;
    height: 60px;
    margin-top: 0.75 * $font-size-base;
  }
}
