.product-teaser {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  &-swiper {
    .swiper-wrapper {
      height: initial;
    }

    .swiper-slide {
      width: 100%;
      height: auto;
      position: relative;
      transition-property: transform;
      display: grid;
      flex-direction: column;
    }
  }

  &__pagination {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > .swiper-button-prev,
    > .swiper-button-next {
      position: relative;
      transform: none;
    }
  }

  &__outer {
    text-decoration: none;
    padding: 0 10px;
    color: inherit;

    &:hover {
      text-decoration: none;
    }
  }

  &__body {
    &__outer {
      flex: 1 0 auto;
    }

    &.card-body {
      padding: 14px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &__outer {
        display: block;
        flex: 1 0 auto;
      }
    }
  }

  &__name {
    font-size: $font-size-base * 1.5;
    margin-bottom: 0.9375 * $font-size-base;
    line-height: 1.625 * $font-size-base;
    font-weight: bold;
  }

  &__image.card-img-top {
    position: relative;
    flex: 0 0 auto;
    height: initial;

    &__main {
      max-height: 250px;
      height: 100%;
      object-fit: cover;

      @include media-breakpoint-up(md) {
        max-height: initial;
      }
    }

    &__inner {
      position: relative;
      height: 100%;

      > div {
        height: 100%;
      }
    }
  }

  &__smallGroupImage {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 80px;
  }

  &__markerPriceImage {
    width: 50px;
    height: 50px;
  }

  &__markerImage {
    width: 24px;
    height: 24px;

    &__container {
      display: flex;
      align-items: center;
      justify-content: center;
      max-height: 48px;
      height: 100%;
      width: 48px;
      border-radius: 4px;
      background-color: rgba(255, 255, 255, 0.8);
      padding: 12px;
      position: absolute;
      top: 12px;
      right: 12px;
    }
  }

  &__description {
    margin-bottom: 0.875 * $font-size-base;

    ul {
      padding-inline-start: 20px;
      list-style-type: square;
    }

    li::marker {
      color: $blue;
    }
  }

  &__partner__image {
    max-width: 75px;
    margin: 0.3125 * $font-size-base 0 0;
  }

  &__date {
    display: flex;
    justify-content: space-between;

    > div {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      &:first-child {
        justify-content: flex-start;
      }
    }

    &__headline {
      font-weight: 700;

      + .product-teaser__partner__image {
        margin-top: 0;
      }
    }

    &__text {
      font-size: 0.75 * $font-size-base;
      margin-bottom: 0.3125 * $font-size-base;
    }
  }

  &__wasPrice {
    text-align: right;
    font-size: $font-size-base;
    line-height: 1.125 * $font-size-base;
    .iso__price__value {
      text-decoration: line-through;
    }
  }

  &__price {
    text-align: right;
    white-space: nowrap;

    &--amount {
      font-size: $font-size-base * 1.8;
      line-height: $font-size-base * 1.8;

      &--strike {
        color: $red;
      }
    }
    &--text {
      font-size: $font-size-base * 1.25;
    }

    color: $secondary;
    font-weight: bold;
  }

  &__context {
    font-size: $font-size-base * 0.8;
    text-align: right;
    margin-bottom: 0.75 * $font-size-base;
    color: $black;
  }

  &__cta {
    width: 100%;
    padding: 0.625 * $font-size-base 0 !important;
    border-radius: 4px;
  }

  //CUSTOM STYLES
  :hover {
    cursor: pointer;
  }

  .col {
    padding-left: 0.3125 * $font-size-base !important;
    padding-right: 0.3125 * $font-size-base !important;
  }

  /* TODO these button styles are only a temporary solution, maybe button styles can be refactored later,
  since the button styles in CMS and IBE are currently different */
  .btn-primary:hover {
    color: $black;
    border-color: #ccb000;
  }

  .btn {
    font-size: 20px;
  }
}
