@import '../mediaQueries';
@import '../Vars';

.form-module {
  &__headline {
    color: $orange;
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 15px;

    &--suffix {
      color: $gray-800;
      font-size: $font-size-base * 0.9;
    }
  }

  .form-control {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    border-bottom-width: 2px;
    font-weight: 400;
    padding-left: 0;

    &:hover,
    &:focus {
      box-shadow: none;
      border-bottom-color: $secondary;
    }
  }

  .iso__date-range-input,
  #paxSelection .iso__dropdown__toggle,
  .select__control {
    border-width: 0 0 2px;
    font-weight: 400;
    padding-left: 0;
    min-height: 38px;

    &:hover,
    &:focus {
      box-shadow: none;
      border-bottom-color: $secondary !important;
    }
  }

  .select__value-container {
    padding-left: 0;
  }

  &__lower-label {
    font-size: $font-size-base * 0.86;

    @include medium {
      position: relative;
      right: -100px;
    }
  }

  .form__error {
    position: relative;
    top: 4px;
  }
}

.ibe__flight .form-module__headline {
  color: $secondary;
  font-size: 22px;
}

.checkbox {
  font-size: $font-size-base * 0.9;
  font-weight: 700;
  cursor: pointer;

  &__label {
    margin-left: 8px;
    position: relative;
    top: -1px;
  }
}

input.form-check-input {
  margin-left: 0;
}

.form-input-container {
  padding-bottom: 20px;
}

.travellerData {
  .custom-control-label {
    font-weight: 600;
    padding-left: 15px !important;
  }
}

.custom-control-label {
  font-size: $font-size-base;
}

/** fix display of button svg on form error (invalid date) */
.iso__date-picker__keyboardSupportButton svg {
  position: relative;
  right: auto;
  bottom: auto;
}

.iso__hotel-card {
  background: #ffffff;
  box-shadow: 0 0 10px #3f4f5a28;
  max-width: 413px;

  img {
    aspect-ratio: 770 / 476;
  }

  .card-body {
    padding: 16px;

    .card-title {
      font-weight: bold;
      font-size: 20px;

      .hotel-name {
        margin-right: 10px;
        white-space: normal;
      }
    }

    .card-text {
      .truncated-text {
        font-size: 16px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        white-space: initial;
      }
    }

    .more-info-btn {
      padding-left: 0;
      padding-right: 0;
      font-size: 16px !important;
      font-weight: 600;
    }
  }

  .card-footer {
    background: #ffffff;
    display: flex;
    align-items: center;
    padding: 16px;

    .custom-radio.custom-control {
      .extended__booking__item.extended__booking__item--selected {
        font-weight: 600;
        color: $mediumblue;
      }

      .custom-control-label {
        padding-top: 0;
        padding-bottom: 0;
      }

      .extended__booking__whitespace {
        margin-left: 4px;
        font-size: 16px;
        margin-top: 4px;

        @include large {
          font-size: 20px;
          margin-top: -0.1px;
        }
      }
    }

    .additional-costs {
      flex: 1;
      text-align: right;
      font-weight: bold;
      font-size: 16px;

      @include large {
        font-size: 20px;
      }

      .iso__price__suffix {
        font-size: 0.75 * $font-size-base;
        font-weight: normal;
      }
    }

    .per-person {
      margin-top: -8px;
      font-size: 12px;
    }
  }
}

.iso__hotel-card.hotel-unavailable {
  opacity: 0.5;

  .unavailable-text {
    width: 100%;
    line-height: 45px;
    height: 45px;

    @include smallMax {
      line-height: 29px;
      height: 29px;
    }
  }
}

.iso__hotel-modal {
  .modal-body {
    .carousel {
      &-indicators {
        display: none;
      }

      .carousel-item {
        img {
          aspect-ratio: 770/476;
        }

        .carousel-caption {
          display: block !important;
          position: absolute;
          right: 0;
          bottom: 0;
          text-align: center;
          color: $black;
          letter-spacing: 0;
          font-size: $font-size-base;
          line-height: 20px;
          padding: 10px 24px 10px 34px;
          background-color: #ffffff99;
          left: auto;
        }

        p,
        h3 {
          margin: 0;
        }
      }

      &-control-prev,
      &-control-next {
        top: 50%;
        width: 40px;
        height: 40px;
        border-radius: 4px;
        background-color: #fff;
        opacity: 0.8;
        color: #004f9e;
        display: flex;

        &-icon {
          background-image: none !important;
          font-size: 45px;
          line-height: 8px;
        }
      }

      &-control-prev {
        left: 16px;

        &-icon {
          &:before {
            content: '\2039';
          }
        }
      }

      &-control-next {
        right: 16px;

        &-icon {
          &:before {
            content: '\203A';
          }
        }
      }
    }

    .hotel-card-content {
      padding-right: 1.75 * $font-size-base;

      .header {
        display: flex;
        align-items: normal;
        padding-top: 0;

        .hotel-name {
          font-weight: bold;
          font-size: 20px;
          margin-right: 10px;

          @include medium {
            font-size: 24px;
          }
        }

        .additional-costs {
          font-weight: bold;
          font-size: 24px;
          flex: 1;
          text-align: right;
          display: flex;
          flex-direction: column;
          min-width: 110px;

          .per-person {
            font-weight: normal;
            font-size: 12px;
            margin-top: -10px;
          }
        }

        margin-bottom: 0;
      }

      .description-divider {
        margin-left: -28px;
        margin-right: -28px;
      }
    }
  }
}

.hotel-card .iso__star__rating,
.hotel-modal .iso__star__rating {
  margin-left: 20px;
}

.checkout__extras.traveler {
  .traveler-row {
    margin-bottom: 30px;

    .traveler-headline {
      font-size: 20px;
      color: black;

      @include smallMax {
        font-size: 16px;
      }
    }

    .customer {
      font-weight: 600;
      font-size: 14px;
      margin-right: 8px;
    }

    .payment__info__box {
      padding-top: 0;
    }
  }

  .input--error {
    position: initial;
  }

  .form__error {
    top: auto;
  }
}

.checkout-workflow .product-ibe {
  .simple-list__head tr th:nth-last-child(2) {
    hyphens: auto;
    -webkit-hyphens: auto;
  }
}

.optional-offer__extra__content__price {
  .per-person {
    margin-top: -8px;
    font-size: 12px;
  }

  > span > span .iso__price__value {
    font-size: 20px;
  }
}

.custom-checkbox .custom-control-label::before,
.custom-checkbox .custom-control-label::after {
  top: 0.4375 * $font-size-base !important;
}

.element-renderer {
  // This prevents layout shifts, when one field is empty and required,
  // while the one next to it is not required and empty
  height: fit-content !important;
}

.extended__booking__warning {
  font-size: 0.75 * $font-size-base;
  font-weight: 600;
  font-style: italic;
  color: gray;
}
