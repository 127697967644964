@import '../mediaQueries';
@import '../Vars';

.custom-control-input ~ .custom-control-label {
  padding: 0.15625 * $font-size-base 8px 0.15625 * $font-size-base;

  &:before,
  &:after {
    width: 20px;
    height: 20px;
  }
}

.insurance__features .custom-control-label,
.iso__checkout__paymentOption .custom-control-label {
  &:before,
  &:after {
    border-radius: 50%;
  }
  &:after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  }
}

.bus-stops {
  @include largeListWithoutLinebreak {
    flex: 1 1 100%;
  }
}

.geounit-checkbox {
  & .Popover {
    top: 37px !important;
  }

  &__popover {
    @include largeListWithoutLinebreakMax {
      transform: none;
    }
  }

  &__field {
    display: flex;
    label > span {
      position: relative;
      top: 3px;
    }
  }

  &__container__inner {
    & > div:first-child {
      flex: 1 1 100%;
      @include small {
        flex: 1 1 100%;
      }

      & .geounit-checkbox__field {
        margin: 0;
      }
    }
  }

  &__container__group {
    & > div:first-child {
      span {
        font-weight: $font-weight-bold;
      }
    }
  }

  .form-control {
    border-color: $gray-400;
    border-width: 0 0 2px;
    border-radius: 0;
    height: calc(1.5em + 0.3125rem + 2px);
    color: $input-color;
    border-bottom-color: $darkerBlack !important;

    &::placeholder {
      color: $input-color;
    }

    &:hover {
      border-color: $secondary;
    }

    &:focus,
    &:active {
      outline: 0;
      box-shadow: none;
    }
  }

  .geounit-checkbox__angle {
    color: $button-primary-color;
    font-size: $font-size-base * 1.1;
  }

  .btn.btn-secondary {
    min-width: 150px;
  }

  &__btn-remove {
    position: relative;
    flex: 1 1 72%;
    font-weight: 600;

    &:before {
      content: '\00d7';
      font-size: 25px;
      margin-left: -18px;
      position: absolute;
      top: -2px;
      display: none;
    }

    @include small {
      &:before {
        display: inline-block;
      }
    }
  }

  &__container {
    display: flex;
    flex-direction: column;

    > div:first-child {
      order: 1;
    }

    > div:last-child {
      order: 2;
      margin-top: 0.9375 * $font-size-base !important;

      > button {
        width: 100%;
      }
    }
  }
}

.select__control {
  .select__placeholder {
    color: $input-placeholder-color;
  }
}

.react-datepicker__input-container {
  > button.input--filled > div > div {
    color: $input-color !important;
  }

  > button > div > div {
    color: $input-placeholder-color !important;

    .input--error & {
      color: $red !important;
    }
  }

  > button[data-form-type='other'] > div > div {
    color: $input-color !important;

    .input--error & {
      color: $red !important;
    }
  }
}

.form-module .react-datepicker {
  .react-datepicker__current-month {
    display: none;
  }

  .react-datepicker__navigation {
    display: none;
  }

  .react-datepicker__header__dropdown {
    margin: -0.625 * $font-size-base -0.625 * $font-size-base 0;
    padding: 0.625 * $font-size-base;
    background-color: $secondary;
  }

  .react-datepicker__month-dropdown-container,
  .react-datepicker__year-dropdown-container {
    width: calc(50% - 6px);

    > select {
      width: 100%;
      height: 32px;
      border: 0;
      border-radius: 4px;
    }
  }

  .react-datepicker__triangle {
    border-bottom-color: $secondary !important;

    &::before {
      border-bottom-color: $secondary !important;
    }
  }

  .react-datepicker__day--selected {
    background-color: $secondary;
  }

  .react-datepicker__header {
    padding-top: 0;
  }

  .react-datepicker__month-dropdown-container {
    margin: 0 6px 0 0;
  }

  .react-datepicker__year-dropdown-container {
    margin: 0 0 0 6px;
  }
}

.react-datepicker .react-datepicker__day--keyboard-selected:not(.react-datepicker__day--today) {
  color: initial;
  background-color: initial;

  &:hover {
    background-color: #ededed;
  }

  &.react-datepicker__day--disabled {
    color: $gray-400;
  }
}

.react-datepicker-wrapper {
  button {
    text-align: left;

    > div > div {
      position: relative;
    }
  }
}

.iso__service-search {
  .react-datepicker__input-container {
    > button > div > div {
      color: $input-color !important;
    }
  }
}

input.form-control {
  color: $darkerBlack !important;
  box-shadow: none;

  .input--error & {
    color: $red !important;

    &::placeholder {
      color: $red !important;
    }
  }

  &::placeholder {
    color: $lightgrey !important;
  }
}

.input--filled {
  border-bottom-color: $darkerBlack;

  &.paxRoomSelection .iso__dropdown__toggle,
  .iso__date-range-input,
  &.iso__date-range-input,
  .select__control,
  .geounit-checkbox &,
  &.form-control {
    border-bottom-color: $darkerBlack !important;
  }

  .select__value-container .select__single-value {
    color: initial;
  }
}

.product-select-async .select__value-container .select__single-value {
  color: $darkerBlack !important;
}

.select__value-container .select__single-value {
  color: $lightgrey;
}

.paxRoomSelection {
  .iso__dropdown__toggle {
    text-align: left;
  }

  .list-group-item {
    padding: 0;
    border: 0;

    .row {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }

    .col {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}

.input--error {
  * {
    color: $red !important;
  }

  &.paxRoomSelection .iso__dropdown__toggle,
  .iso__date-range-input,
  &.iso__date-range-input,
  .select__control,
  .geounit-checkbox &,
  input.form-control,
  &.form-control {
    border-bottom-color: $red !important;
  }
}

.select__menu {
  > .select__menu-list > * {
    &:hover {
      background-color: $secondary;
      color: $white;

      * {
        color: $white;
      }
    }
  }
}

.iso__dropdown__toggle:not(:disabled):not(.disabled),
.iso__date-range-input.custom-button {
  &:focus,
  &:active {
    box-shadow: none;
    background-color: $white;
  }
}
