.iso__price__overview__header {
  padding: 0.625 * $font-size-base;
  border-bottom: 1px solid #e4e7ec;

  > div {
    width: 100%;
    display: flex;
    justify-content: space-between;

    > span:first-child {
      font-weight: 600;
      flex: 1 0 auto;
    }

    > span:last-child {
      text-align: right;
    }
  }

  > h4 {
    font-weight: 600;
    margin-top: 0.625 * $font-size-base;
    margin-bottom: 0.625 * $font-size-base;
    color: $black;
  }
}
