.bestprice-details {
  &__headline {
    margin-bottom: 0;
    position: relative;
    top: -2px;
    font-size: 30px;
    font-weight: 600;
  }

  &__duration {
    .camper-item__popover__icon {
      font-size: $font-size-base * 1.05;
      margin-left: 2px;
      position: relative;
      top: 2px;
    }
  }

  .iso__service-search {
    > div > div {
      margin-top: 0.625 * $font-size-base;

      @include small {
        margin-top: 0;

        &:last-child,
        &:nth-last-child(2) {
          margin-top: 0.625 * $font-size-base;
        }
      }

      @include xlarge {
        margin-top: 0;
      }
    }
  }
}

.information-layer {
  &__modal {
    margin: 0 auto;
    max-width: 46.875 * $font-size-base !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) !important;
    transform: translate(-50%, -50%) !important;
    .modal-content {
      height: auto !important;
    }

    &__close__container {
      margin-top: 0 !important;
    }

    .image-gallery {
      margin-top: 1.25 * $font-size-base;

      &-slides {
        width: 100%;
      }
    }
    .image-gallery-content .image-gallery-slide .image-gallery-image,
    .image-gallery-thumbnail .image-gallery-thumbnail-image {
      -webkit-backface-visibility: hidden;
      -ms-transform: translateZ(0); /* IE 9 */
      -webkit-transform: translateZ(0); /* Chrome, Safari, Opera */
      transform: translateZ(0);

      image-rendering: -moz-crisp-edges; /* Firefox */
      image-rendering: -o-crisp-edges; /* Opera */
      image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
      image-rendering: crisp-edges;
      -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
    }
  }
}

.information-layer__modal {
  max-width: 50 * $font-size-base !important;
}

.information-layer__modal__container--pointer {
  cursor: pointer;

  .modal-dialog {
    cursor: default;
  }
}
