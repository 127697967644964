#iso {
  /* Provide your colors in hsl format! */
  --primary: hsl(48, 98%, 58%);
  --primary-h: 48;
  --primary-s: 98%;
  --primary-l: 58%;
  --primary-a: 1;

  --primaryButtonColor: hsl(209, 89%, 32%);
  --primaryButtonColor-h: 209;
  --primaryButtonColor-s: 89%;
  --primaryButtonColor-l: 32%;
  --primaryButtonColor-a: 1;

  --blue: hsl(209, 89%, 32%);
  --blue-h: 209;
  --blue-s: 89%;
  --blue-l: 32%;
  --blue-a: 1;

  --darkblue: hsl(210, 96%, 21%);
  --darkblue-h: 210;
  --darkblue-s: 96%;
  --darkblue-l: 21%;
  --darkblue-a: 1;

  --mediumblue: hsl(210, 100%, 31%);
  --mediumblue-h: 210;
  --mediumblue-s: 100%;
  --mediumblue-l: 31%;
  --mediumblue-a: 1;
}
