@import '../mediaQueries';

%input-style {
  padding-left: 50px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid $semi-lightgrey !important;
  border-color: $semi-lightgrey;
  color: $black;

  &:focus,
  &:active {
    outline: 0;
    box-shadow: none;
  }

  &::placeholder {
    color: $black !important;
  }

  @include small {
    height: 60px;
  }
}
