@import './mediaQueries';
@import './Vars';

#iso {
  position: relative;

  &.whitelabel__container {
    padding-left: 0.3125 * $font-size-base;
    padding-right: 0.3125 * $font-size-base;
  }

  #iso__best-price-availability > div > div:nth-child(2),
  .iso__package__container__inner {
    position: sticky !important;
    top: 91px !important;
    z-index: 10;
  }

  .btn.btn-primary,
  .btn.btn-secondary,
  .btn.btn-outline-primary,
  .btn.btn-outline-secondary {
    min-height: 40px;
    padding-left: 25px;
    padding-right: 25px;

    @include small {
      min-height: 60px;
    }

    &.best-price__availability__btn,
    &.iso__package-item__button,
    &.picture-gallery__open-slider {
      height: initial;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .checkout-workflow {
    .btn.btn-primary,
    .btn.btn-secondary {
      @media (min-width: 991px) {
        padding-left: 65px;
        padding-right: 65px;
      }

      @include smallMax {
        padding: 0 15px;
      }
    }

    .summary-fixed-bottom-bar {
      .next-btn {
        @include xsMax {
          width: 100%;
        }
      }

      .price {
        @include xsMax {
          display: inline-block;
          margin-right: 8px;
          font-weight: normal !important;
          font-size: 12px !important;
        }

        @include smallMax {
          display: inline-block;
          margin-right: 8px;
        }
      }

      .summary__price {
        @include xsMax {
          display: inline-block;
          font-weight: 600;
          font-size: 20px;
          color: #eb6708;
        }

        @include smallMax {
          display: inline-block;
        }
      }

      .price-detail-btn {
        @include xsMax {
          font-weight: normal;
          font-size: 16px;
        }
      }
    }

    .iso__imageProgressBar__stepperItem__stepImage--completed {
      color: #00c187;
    }

    .iso__imageProgressBar__stepperItem:not(.iso__imageProgressBar__stepperItem--active) {
      @include smallMax {
        display: none;
      }
    }

    .optional-offer__extra__list {
      .btn.btn-primary,
      .btn.btn-secondary {
        padding-left: 25px;
        padding-right: 25px;
      }
    }

    .price {
      font-size: $font-size-base * 1.18;
      font-weight: 700;

      > b {
        margin-left: 12px;
        font-weight: 700;
      }
    }

    .whitelabel-wrapper {
      background-color: white;
      padding: 28px;
    }
  }

  .iso__three-column-list-item-layout__info {
    padding-bottom: 0.3125 * $font-size-base !important;
    background-color: transparent !important;
  }

  .link-button {
    @extend .btn;
    @extend .btn-primary;
  }

  .accommodation-adapt-overlay .iso__input-plus-minus {
    max-width: initial;
  }

  .iso__input-plus-minus-input-wrapper {
    z-index: 0;
  }

  .react-datepicker {
    &__day {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__navigation {
      &.react-datepicker__navigation--next {
        border: 0.625 * $font-size-base solid transparent;
        border-left-color: $secondary;
        top: 22px;
        right: 6px;
      }

      &.react-datepicker__navigation--previous {
        border: 0.625 * $font-size-base solid transparent;
        border-right-color: $secondary;
        top: 22px;
        left: 6px;
      }
    }
  }

  .iso__accommodation-adapt-input-plus {
    max-width: 140px;
  }

  .accommodation-adapt-overlay .iso__input-plus-minus-button {
    min-height: 40px !important;
    width: 40px !important;
    padding-left: 0;
    padding-right: 0;

    .iso__input-plus-minus-default-icon {
      position: relative;
      left: -1px;
    }
  }

  .iso__accommodation-board-summary-card-button.btn.btn-secondary {
    min-height: 40px;

    @include small {
      min-height: 40px;
    }
  }

  .iso__three-column-list-item-layout,
  .iso__serviceList__filterContainer {
    background-color: white;
  }

  .iso__three-column-list-item-layout__button {
    padding: 14px;
    @media (min-width: 1200px) {
      padding: 0 8px 4px 0;
    }
  }

  .iso__three-column-list-item-layout__info-inner {
    padding-left: 14px;
    padding-right: 14px;
  }

  .iso__three-column-list-item-layout__content {
    border-right: none;
    @media (min-width: 1200px) {
      border-right: 1px solid #e4ecef;
    }
  }
}

.product-teaser {
  @media (max-width: 768px) {
    box-shadow: none;
    border: 1px solid rgba(40, 40, 47, 0.125) !important;
  }
}
