@import '../Vars';
@import '../mediaQueries';

.insurance {
  &__header {
    font-size: 1.5625 * $font-size-base;
    font-weight: bold;
  }

  &__logo {
    text-align: center;

    @include small {
      text-align: left;
    }

    img {
      max-width: 180px;
    }
  }

  &__features {
    margin-top: 24px;

    .col {
      display: flex;
      flex-direction: column;
    }

    .card {
      box-shadow: 0 4px 10px #3f4f5a28;
      border-radius: 0;
      height: 100%;

      .card-header,
      .card-footer {
        background-color: inherit;
      }

      .card-header {
        padding: 12px 24px !important;
        min-height: 72px; /* 2*24 line-height + 2*12 padding */
      }

      .card-footer {
        padding-top: 12px;
        padding-bottom: 12px;

        .row {
          align-items: center;

          .custom-control-label {
            padding: 0 4px;
          }
        }
      }

      .card-header,
      .card-body,
      .card-footer {
        padding-left: 24px !important;
        padding-right: 24px !important;
      }

      .card-body {
        display: flex;
        flex-direction: column;
        padding-top: 14px !important;
        padding-bottom: 14px !important;

        .insurance__item {
          display: flex;
          align-items: flex-start;
          margin-bottom: 5px;
        }

        .insurance__item:last-child {
          margin-bottom: 0;
        }
      }

      .header {
        color: $mediumblue;
        font-weight: bold;
      }

      .item_check {
        margin-right: 5px;
      }

      .item_text {
        font-size: 0.75 * $font-size-base;
        margin-right: 5px;
      }

      .item_info {
        outline: none;
        margin-left: auto;
      }
    }

    .card.no-insurance-bgcolor {
      background-color: #fff9f5 !important;
    }

    .card.is-recommendation-bgcolor {
      background-color: #e2f0ff !important;
    }

    .card.with-deductible-bgcolor {
      background-color: white !important;
    }

    .no-insurance {
      img {
        float: left;
        margin-right: 6px;
        width: 24px;
        align-self: flex-start;
      }

      p {
        display: flex;
        font-size: 0.75 * $font-size-base;
      }

      .card-body {
        flex-direction: unset;
      }
    }
  }

  &__highlight {
    text-align: center;
    display: flex;
    justify-content: center;

    span {
      padding-top: 4px;
      padding-bottom: 4px;
    }

    span.recommendation {
      width: 80%;
      color: white;
      background-color: $mediumblue;
    }
  }

  &__selection {
    font-weight: bold;
  }

  &__price {
    font-weight: bold;
  }

  &__legal-info {
    margin-top: 26px;
  }

  &__none-selected-validation {
    margin-top: 16px;

    .alert {
      margin-bottom: 0;
    }
  }

  &__wrapper {
    padding: 0 0.85 * $font-size-base;

    .row {
      margin-left: 0 !important;
      margin-right: 0 !important;

      .col {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }

      div[class*='col-'] {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }

    .insurance-icon {
      margin-right: 5px;
    }
  }
}

.bs-tooltip-auto.tooltip-popper {
  padding: 0;
}

.tooltip-popper {
  max-width: 350px;
  opacity: 1 !important;
  box-shadow: 0px 4px 10px #3f4f5a28;
  border-radius: 2px;
  padding: 0;
  z-index: 2;
  .arrow {
    display: none;
  }
}

.tooltip-inner {
  background-color: white;
  color: black;
  max-width: 350px;
  padding: 16px;

  .tooltip__text {
    font-size: 0.875 * $font-size-base;
    font-weight: bold;
    margin-bottom: 6px;
  }

  .tooltip__info {
    font-size: 0.75 * $font-size-base;
    text-align: left;
  }
}
