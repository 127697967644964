@import 'templates';

.iso__service-search {
  button.iso__date-range-input,
  .select__dropdown-indicator,
  .iso__custom__product__select__angle__wrapper,
  .geounit-checkbox__angle__wrapper {
    > svg {
      display: none;
    }

    &::after {
      content: '';
      border: solid $secondary;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 3px;
      transform: rotate(45deg);
      border-radius: 1px;
      right: 6px;
      transition: transform 0.25s ease-in-out;
    }
  }

  button.iso__date-range-input::after {
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    right: 4px;
  }

  .select__dropdown-indicator {
    position: relative;
    right: -1px;
  }

  .iso__date-range--open button.iso__date-range-input,
  .select__control--menu-is-open .select__dropdown-indicator,
  .iso__custom__product__select__input--open .iso__custom__product__select__angle__wrapper,
  .geounit-checkbox--open .geounit-checkbox__angle__wrapper {
    &::after {
      transform: rotate(225deg);
    }
  }
}

.tab-container {
  &__tab-pane {
    padding: 15px;

    @include small {
      padding: 1em;
    }

    @include medium {
      padding: 1.5em 1.8em;
    }

    .iso__package__container > div {
      margin-bottom: 0 !important;
    }

    .iso__service-search {
      padding: 0;
    }
  }

  .tab-content {
    background-color: $white;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }

  .nav.nav-tabs {
    position: relative;
    left: -1px;
    flex-wrap: nowrap;
    border-bottom: 0;
  }

  .nav-item {
    max-width: 265px;
    width: 100%;
    cursor: pointer;

    @include small {
      max-width: 265px;
    }

    .nav-link {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      text-align: center;
      font-weight: 600;
      background-color: $bluegrey;
      color: $black;
      height: 100%;

      &.active {
        background-color: $white;
        color: $mediumblue;
        border-top: 4px solid $mediumblue;
      }

      @include small {
        padding: 15px 30px;
        font-size: $font-size-base;
      }

      @include medium {
        font-size: 1.0625 * $font-size-base;
      }
    }
  }
}

.thg-search .search-form {
  &__submit {
    border-radius: 4px !important;
    width: 100%;

    &:disabled:hover {
      border-color: $primary;
    }
  }

  &__icon {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    color: $semi-lightgrey !important;
    width: initial !important;
    font-size: 1.375 * $font-size-base;
    cursor: text;
    pointer-events: none;
  }

  &__input {
    @extend %input-style;
  }

  .iso__package__container .alert {
    margin-top: 0.9375 * $font-size-base;
  }

  .iso__service-search {
    &__button {
      @extend .search-form__submit;
    }

    .select__dropdown-indicator::after,
    button.iso__date-range-input::after,
    .iso__custom__product__select__angle__wrapper::after,
    .geounit-checkbox__angle__wrapper::after {
      content: none;
    }

    .row div[class^='col-'] > div {
      position: relative;
    }

    .iso__service-search__icon {
      @extend .search-form__icon;

      top: calc(50% + 13px);
      left: 15px;
    }

    label {
      font-weight: 600;
      margin-bottom: 0.3125 * $font-size-base;
      font-size: $font-size-base;
    }
  }

  .iso__custom__product__select__input {
    border-bottom: 0;
  }

  .input--filled {
    border-bottom: 0;
  }

  .iso__service-search {
    &__row__1 {
      &__col__1 {
        order: 2;
        margin-top: 0.9375 * $font-size-base;

        @include small {
          margin-top: 0;
        }
      }

      &__col__2 {
        order: 1;
      }

      &__col__3 {
        order: 3;
      }
    }

    &__row__2 {
      &__col__2 {
        margin-top: 0.9375 * $font-size-base;

        @include small {
          margin-top: 0;
        }
      }
    }

    .iso__date-range-input {
      @extend %input-style;

      padding-right: 25px;

      > div {
        white-space: nowrap;
        overflow: hidden;

        > div {
          flex-wrap: nowrap;
        }
      }
    }

    div.select__control,
    input.form-control {
      @extend %input-style;
    }
  }

  .best-price-wrapper {
    margin-left: 0 !important;
    margin-right: 0 !important;
    > [class*='col-'] {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}

.ibe-widget .iso__service-search {
  padding: 1.875 * $font-size-base;
}

.search-list {
  margin-bottom: 1.875 * $font-size-base;

  .search-form {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    padding: 1.5 * $font-size-base 1.875 * $font-size-base 1.625 * $font-size-base;
    background-color: $white;
    margin-bottom: 2.1875 * $font-size-base;
  }
}

.search-widget {
  .iso__service-search {
    box-shadow: none;
  }
}

.result-list {
  &__number {
    font-weight: 700;
    font-size: 1.5 * $font-size-base;
    margin-bottom: 1.25 * $font-size-base;
  }

  &__lazy-element {
    display: flex;
    flex: 0 0 100%;

    @extend .col;
    @extend .col-sm-6;
    @extend .col-lg-4;

    > div > div,
    > div {
      display: flex;
      width: 100%;
    }
  }
}
