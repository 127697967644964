.package__search__content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  > div {
    margin: 5px 30px 5px 0;
  }

  svg {
    color: $secondary;
    margin-right: 10px;
  }
}

.search-collapsible__outer {
  > div:first-child {
    background: #fff;
    box-shadow: 0 0 8px #d7d7d7;
    margin: 0;
    padding: 0.625 * $font-size-base;
    border-radius: 0.15625 * $font-size-base;

    .search-collapsible {
      align-items: center;
    }

    .link-button {
      @extend .btn;
      @extend .btn-primary;

      white-space: nowrap;
      padding-left: 20px;
      padding-right: 20px;
      margin-left: 25px;
      margin-top: 15px;

      @include small {
        margin-top: 0;
      }
    }
  }

  .search-collapsible {
    flex-direction: column;

    @include small {
      flex-direction: row;
    }

    > div {
      width: 100%;
    }
  }
}
